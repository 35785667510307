import { filter, groupBy, some } from 'lodash';
import _startsWith from 'lodash/startsWith';
import _toUpper from 'lodash/toUpper';
import { ProvisioningStatus } from 'src/app/enums/ProvisioningStatus';
import { IProductInstance } from 'src/app/interfaces/ICommon';
import { IRbacUserRole } from 'src/app/interfaces/IUserRoles';
import { FEATURE_FLAG_PREFIX } from 'src/app/utils/constant';
import { IGroup } from '../../interfaces/IGroups';
import { IRolesWithInstances } from '../../interfaces/IUsers';
import {
  IDependentOption,
  IManagedRbacGroups,
  IGroupToRoleRelation,
} from '../users/interfaces';
// Returns only instances in which "provisioningStatus" is "FINISHED"
export const getProvisionedProductInstances: (
  productInstances: IProductInstance[]
) => IProductInstance[] = (productInstances) => {
  return filter(productInstances, {
    provisioningStatus: ProvisioningStatus.FINISHED,
  });
};

// Returns Product instance display name in format "<region_name> - <external_tenant_id>"
export const getProductInstanceDisplayName: (
  productInstance: IProductInstance,
  useDescription?: boolean
) => string = (productInstance, useDescription) => {
  const postfix = productInstance.externalTenantId
    ? ` - ${productInstance.externalTenantId}`
    : '';

  return useDescription
    ? `${productInstance.region.description}${postfix}`
    : `${productInstance.region.name}${postfix}`;
};

export const convertFeatureFlagsToSet = (
  flags: string[] | null | undefined
): Set<string> => {
  return (flags ?? []).reduce((previous, current) => {
    const upperCaseFlag = _toUpper(current);
    if (_startsWith(upperCaseFlag, FEATURE_FLAG_PREFIX)) {
      const flagWithoutPrefix = upperCaseFlag.substring(
        FEATURE_FLAG_PREFIX.length
      );

      previous.add(flagWithoutPrefix);
    }

    return previous;
  }, new Set<string>());
};

export const convertRolesToInstanceDictionary = (
  roles: IRolesWithInstances[]
): IDependentOption => {
  return roles.reduce<IDependentOption>((acc, role) => {
    acc[role.productAndRole] = role.instances.map((instance) => {
      return {
        value: instance.id,
        roleId: role.id,
        label: getProductInstanceDisplayName(instance),
        selected: false,
      };
    });
    return acc;
  }, {});
};

export const classifyGroupsForKpa = (
  tenantIds: string[],
  allGroups: IGroup[],
  groupsToRolesRelations: { items: IGroupToRoleRelation[] }
): IManagedRbacGroups => {
  const relationsByGroupId = groupBy(groupsToRolesRelations.items, 'groupId');

  if (!tenantIds.length) {
    return {
      fullyManagedGroups: [],
      partiallyManagedGroups: [],
      noRolesGroups: [],
    };
  }

  return allGroups.reduce<IManagedRbacGroups>(
    (prev, curr) => {
      const groupRelations = relationsByGroupId[curr.id];

      if (!groupRelations) {
        prev.noRolesGroups.push(curr);
        return prev;
      }

      const isFullyManagedGroup = groupRelations.every((gr) =>
        tenantIds.includes(gr.tenantId)
      );

      if (isFullyManagedGroup) {
        prev.fullyManagedGroups.push(curr);
      } else {
        prev.partiallyManagedGroups.push(curr);
      }

      return prev;
    },
    { fullyManagedGroups: [], partiallyManagedGroups: [], noRolesGroups: [] }
  );
};

export type TFilterRolesByProducts = (
  allRoles: IRbacUserRole[],
  allProductsInstances: IProductInstance[]
) => IRbacUserRole[];
// Returns only roles for which Enterprise has at least one Tenant (Product Instance).
// Filters ONLY STATIC roles by Tenants. Roles with other types returns as is.
export const filterRolesByExistingProducts: TFilterRolesByProducts = (
  allRoles,
  allProductsInstances
) => {
  return filter(allRoles, (role: IRbacUserRole) => {
    const { type, productKey } = role;

    if (type !== 'STATIC') {
      return true;
    }

    return some(allProductsInstances, { productType: productKey });
  });
};
