import { createReducer } from 'redux-create-reducer';

import {
  FETCH_INVITE_PRODUCTS_PENDING,
  FETCH_INVITE_PRODUCTS_SUCCESS,
  FETCH_INVITE_PRODUCTS_ERROR,
  FETCH_PRODUCTS_LIST_PENDING,
  FETCH_PRODUCTS_LIST_SUCCESS,
  FETCH_PRODUCTS_LIST_ERROR,
  SHOW_ATTACH_PRODUCT_MODAL,
  HIDE_ATTACH_PRODUCT_MODAL,
  SHOW_DECLINE_PRODUCT_MODAL,
  HIDE_DECLINE_PRODUCT_MODAL,
  HIDE_SUCESS_PRODUCT_NOTIFICATION,
  SHOW_ERROR_ATTACH_PRODUCT_NOTIFICATION,
  HIDE_ERROR_PRODUCT_NOTIFICATION,
  SHOW_SUCCESS_ATTACH_PRODUCT_NOTIFICATION,
  SHOW_SUCCESS_DECLINE_PRODUCT_NOTIFICATION,
  SHOW_ERROR_DECLINE_PRODUCT_NOTIFICATION,
  ATTACH_PRODUCT_INVITE_PENDING,
  ATTACH_PRODUCT_INVITE_SUCCESS,
  ATTACH_PRODUCT_INVITE_ERROR,
  DECLINE_PRODUCT_INVITE_PENDING,
  DECLINE_PRODUCT_INVITE_SUCCESS,
  DECLINE_PRODUCT_INVITE_ERROR,
  POST_BOOTSTRAP_PLATFORM_SUBSCRIPTION,
} from './constants';
import { IOverview, IInviteProduct } from '../../../interfaces/IOverview';
import { IProductTransformed } from '../../../interfaces/IProductList';

export interface IState {
  inviteProducts: {
    items: IInviteProduct[];
    isAttachProductModalVisible: boolean;
    isDeclineProductModalVisible: boolean;
    isSuccessAttachProductNotificationVisible: boolean;
    isSuccessDeclineProductNotificationVisible: boolean;
    isErrorAttachProductNotificationVisible: boolean;
    isErrorDeclineProductNotificationVisible: boolean;
    openedInvitationId: null | string;
    notificationMessage: null | string;
    error: null | string;
    isLoading: boolean;
  };
  customers: {
    items: IProductTransformed[];
    error: null;
    isLoading: false;
  };
}

export const initialState: IOverview = {
  inviteProducts: {
    items: [],
    isAttachProductModalVisible: false,
    isDeclineProductModalVisible: false,
    isSuccessAttachProductNotificationVisible: false,
    isSuccessDeclineProductNotificationVisible: false,
    isErrorAttachProductNotificationVisible: false,
    isErrorDeclineProductNotificationVisible: false,
    openedInvitationId: null,
    notificationMessage: null,
    error: null,
    isLoading: false,
  },
  customers: {
    items: {
      otherProducts: [],
      pendingProducts: [],
    },
    error: null,
    isLoading: false,
  },
};

export default createReducer(initialState, {
  [FETCH_INVITE_PRODUCTS_PENDING]: (state) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isLoading: true,
    },
  }),
  [FETCH_INVITE_PRODUCTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isAttachProductModalVisible: false,
      items: payload,
      isLoading: false,
    },
  }),

  [FETCH_INVITE_PRODUCTS_ERROR]: (state, { payload }) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      items: [],
      error: payload,
      isLoading: false,
    },
  }),

  [FETCH_PRODUCTS_LIST_PENDING]: (state) => ({
    ...state,
    customers: {
      ...state.customers,
      isLoading: true,
    },
  }),
  [FETCH_PRODUCTS_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    customers: {
      ...state.customers,
      items: payload,
      isLoading: false,
    },
  }),
  [FETCH_PRODUCTS_LIST_ERROR]: (state, { payload }) => ({
    ...state,
    customers: {
      ...state.customers,
      error: payload,
      isLoading: false,
    },
  }),

  [ATTACH_PRODUCT_INVITE_PENDING]: (state) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isLoading: true,
    },
  }),
  [ATTACH_PRODUCT_INVITE_SUCCESS]: (state) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isLoading: false,
    },
  }),
  [ATTACH_PRODUCT_INVITE_ERROR]: (state, { payload }) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      error: payload,
      isLoading: false,
    },
  }),

  [DECLINE_PRODUCT_INVITE_PENDING]: (state) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isLoading: true,
    },
  }),
  [DECLINE_PRODUCT_INVITE_SUCCESS]: (state) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isLoading: false,
    },
  }),
  [DECLINE_PRODUCT_INVITE_ERROR]: (state, { payload }) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      error: payload,
      isLoading: false,
    },
  }),

  [SHOW_ATTACH_PRODUCT_MODAL]: (state, { payload: openedInvitationId }) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isAttachProductModalVisible: true,
      openedInvitationId,
    },
  }),
  [HIDE_ATTACH_PRODUCT_MODAL]: (state) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isAttachProductModalVisible: false,
      openedInvitationId: null,
    },
  }),

  [SHOW_DECLINE_PRODUCT_MODAL]: (state, { payload: openedInvitationId }) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isDeclineProductModalVisible: true,
      openedInvitationId,
    },
  }),
  [HIDE_DECLINE_PRODUCT_MODAL]: (state) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isDeclineProductModalVisible: false,
      openedInvitationId: null,
    },
  }),

  [SHOW_SUCCESS_ATTACH_PRODUCT_NOTIFICATION]: (state) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isSuccessAttachProductNotificationVisible: true,
    },
  }),
  [SHOW_SUCCESS_DECLINE_PRODUCT_NOTIFICATION]: (state) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isSuccessDeclineProductNotificationVisible: true,
    },
  }),
  [HIDE_SUCESS_PRODUCT_NOTIFICATION]: (state) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isSuccessAttachProductNotificationVisible: false,
      isSuccessDeclineProductNotificationVisible: false,
    },
  }),
  [SHOW_ERROR_ATTACH_PRODUCT_NOTIFICATION]: (state) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isErrorAttachProductNotificationVisible: true,
    },
  }),
  [SHOW_ERROR_DECLINE_PRODUCT_NOTIFICATION]: (state) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isErrorDeclineProductNotificationVisible: true,
    },
  }),
  [HIDE_ERROR_PRODUCT_NOTIFICATION]: (state) => ({
    ...state,
    inviteProducts: {
      ...state.inviteProducts,
      isErrorAttachProductNotificationVisible: false,
      isErrorDeclineProductNotificationVisible: false,
    },
  }),
  [POST_BOOTSTRAP_PLATFORM_SUBSCRIPTION]: (state) => state,
});
