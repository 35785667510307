import OktaAuth from '@okta/okta-auth-js';
import { AxiosError } from 'axios';
import {
  SET_ENTERPRISE_DETAILS,
  SET_ENTERPRISE_CREATED,
  FETCH_ENTERPRISE_DETAILS_PENDING,
  FETCH_ENTERPRISE_DETAILS_SUCCESS,
  FETCH_ENTERPRISE_DETAILS_ERROR,
  RESET_ENTERPRISE_DETAILS,
  FETCH_ENTERPRISES_PENDING,
  FETCH_ENTERPRISES_SUCCESS,
  FETCH_ENTERPRISES_ERROR,
  CREATE_ENTERPRISE_PENDING,
  CREATE_ENTERPRISE_SUCCESS,
  CREATE_ENTERPRISE_ERROR,
  SHOW_SUCCESS_CREATE_ENTERPRISE_BANNER,
  HIDE_SUCCESS_CREATE_ENTERPRISE_BANNER,
  PRE_SELECT_ENTERPRISE,
  OPEN_ENTERPRISE_MODAL,
  CLOSE_ENTERPRISE_MODAL,
  SET_DEFAULT_ENTERPRISE_PENDING,
  SET_DEFAULT_ENTERPRISE_SUCCESS,
  SET_DEFAULT_ENTERPRISE_ERROR,
  SET_DEFAULT_ENTERPRISE_USER_ID,
  HIDE_SUCCESS_SWITCH_ENTERPRISE_TOAST,
} from './constants';
import { IEnterpriseObject } from '../../interfaces/IEnterpriseList';
import { IActionWithPayload } from '../../interfaces/IOverview';

type ErrorType = AxiosError | Error | { message: string };

export interface IAction<T = object> {
  type: string;
  payload?: T;
}

export const setEnterpriseDetails = (
  enterprise: IEnterpriseObject
): IAction => ({
  type: SET_ENTERPRISE_DETAILS,
  payload: enterprise,
});

export const setEnterpriseCreated = (
  isCreated: boolean
): IActionWithPayload<boolean> => ({
  type: SET_ENTERPRISE_CREATED,
  payload: isCreated,
});

export const fetchEnterprises = (payload?: object): IAction => ({
  type: FETCH_ENTERPRISES_PENDING,
  payload,
});

export const fetchEnterprisesSuccess = (
  enterprises: IEnterpriseObject[]
): IAction => ({
  type: FETCH_ENTERPRISES_SUCCESS,
  payload: enterprises,
});

export const fetchEnterprisesError = (error: ErrorType): IAction => ({
  type: FETCH_ENTERPRISES_ERROR,
  payload: error,
});

export const fetchEnterpriseDetails = (data: object): IAction => ({
  type: FETCH_ENTERPRISE_DETAILS_PENDING,
  payload: data,
});

export const fetchEnterpriseDetailsSuccess = (data: object): IAction => ({
  type: FETCH_ENTERPRISE_DETAILS_SUCCESS,
  payload: data,
});

export const fetchEnterpriseDetailsError = (error: ErrorType): IAction => ({
  type: FETCH_ENTERPRISE_DETAILS_ERROR,
  payload: error,
});

export const resetEnterpriseDetails = (): IAction => ({
  type: RESET_ENTERPRISE_DETAILS,
});

export const createEnterprise = (
  enterpriseName: string,
  oktaAuth: OktaAuth,
  navigate: any
): IAction => ({
  type: CREATE_ENTERPRISE_PENDING,
  payload: {
    enterpriseName,
    oktaAuth,
    navigate,
  },
});

export const createEnterpriseSuccess = (
  enterprise: IEnterpriseObject
): IAction => ({
  type: CREATE_ENTERPRISE_SUCCESS,
  payload: enterprise,
});

export const createEnterpriseError = (error: ErrorType): IAction => ({
  type: CREATE_ENTERPRISE_ERROR,
  payload: error,
});

export const showSuccessCreateEnterpriseBanner = (): IAction => ({
  type: SHOW_SUCCESS_CREATE_ENTERPRISE_BANNER,
});

export const hideSuccessCreateEnterpriseBanner = (): IAction => ({
  type: HIDE_SUCCESS_CREATE_ENTERPRISE_BANNER,
});

export const hideSuccessSwitchEnterpriseToast = (): IAction => ({
  type: HIDE_SUCCESS_SWITCH_ENTERPRISE_TOAST,
});

export const preSelectEnterprise = (
  enterprise?: IEnterpriseObject
): IAction => ({
  type: PRE_SELECT_ENTERPRISE,
  payload: enterprise,
});

export const openEnterpriseModal = (): IAction => ({
  type: OPEN_ENTERPRISE_MODAL,
});

export const closeEnterpriseModal = (): IAction => ({
  type: CLOSE_ENTERPRISE_MODAL,
});

export const setDefaultEnterpriseUserId = (
  payload: IEnterpriseObject
): IAction => ({
  type: SET_DEFAULT_ENTERPRISE_USER_ID,
  payload,
});

export const setDefaultEnterprisePending = (payload: any): IAction => ({
  type: SET_DEFAULT_ENTERPRISE_PENDING,
  payload,
});

export const setDefaultEnterpriseSuccess = (
  payload: IEnterpriseObject
): IAction => ({
  type: SET_DEFAULT_ENTERPRISE_SUCCESS,
  payload,
});

export const setDefaultEnterpriseError = (error: ErrorType): IAction => ({
  type: SET_DEFAULT_ENTERPRISE_ERROR,
  payload: error,
});
