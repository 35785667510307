import { cloneDeep, isObject, merge, omit } from 'lodash';
import { createStore, Action, applyMiddleware, Store, compose } from 'redux';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import actionToObjectMiddleware from './middlewares/action-to-object.middleware';
import createRootReducer from './rootReducer';
import rootSaga from './rootSaga';

interface IStoreWithPersister {
  store: Store<PersistPartial, Action<any>>;
  persistor: Persistor;
}
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['loader'],
  // https://github.com/rt2zz/redux-persist?tab=readme-ov-file#state-reconciler
  stateReconciler: (
    // local storage state
    inboundState: Record<string, {}>,
    originalState: Record<string, {}>,
    // redux store initial state
    reducedState: Record<string, {}>
  ): Record<string, {}> => {
    const newState = cloneDeep(omit(reducedState, ['_persist']));
    return isObject(inboundState)
      ? merge({}, newState, inboundState)
      : newState;
  },
};

// @ts-expect-error
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, createRootReducer());

const middlewares = [actionToObjectMiddleware, sagaMiddleware];

export default function configureStore(): IStoreWithPersister {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return { store, persistor };
}
