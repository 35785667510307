const setCallableActionFactory = (target: Function): any =>
  new Proxy(Reflect.construct.bind(null, target), {
    get(tar, prop, val) {
      // -- access static
      return Reflect.get(target, prop, val);
    },
    set(tar, prop, val) {
      // -- access static
      return Reflect.set(target, prop, val);
    },
    apply(target, thisArg, argumentsList) {
      // -- make the constructor work
      return target({ ...argumentsList, length: argumentsList.length });
    },
  });

export class ActionBase<TPayload> {
  static TYPE: string;
  type: string;
  constructor(public payload?: TPayload) {
    this.type = (<any>this.constructor).TYPE;
  }
}

export function getActionFactory(prefix: string) {
  return function createAction<TPayload>(name: string) {
    const actionFactory = class ActionFactory extends ActionBase<TPayload> {
      static TYPE = `${prefix}/${name}`;
    };

    return setCallableActionFactory(actionFactory);
  };
}

export function getAsyncActionFactory(prefix: string) {
  return function createAsyncAction<
    TPendingPayload,
    TSuccessPayload,
    TErrorPayload = Error | string,
  >(name: string) {
    return class AsyncActionFactory extends ActionBase<
      TPendingPayload | TSuccessPayload | TErrorPayload
    > {
      static Pending = class extends ActionBase<TPendingPayload> {
        static TYPE = `${prefix}/${name}_PENDING`;
      };

      static Success = class extends ActionBase<TSuccessPayload> {
        static TYPE = `${prefix}/${name}_SUCCESS`;
      };

      static Error = class extends ActionBase<TErrorPayload> {
        static TYPE = `${prefix}/${name}_ERROR`;
      };

      static PENDING(args?: TPendingPayload): AsyncActionFactory {
        return new AsyncActionFactory.Pending(args);
      }

      static SUCCESS(args?: TSuccessPayload): AsyncActionFactory {
        return new AsyncActionFactory.Success(args);
      }

      static ERROR(args: TErrorPayload): AsyncActionFactory {
        return new AsyncActionFactory.Error(args);
      }
    };
  };
}
