export enum EnterpriseRegionType {
  APJC = 'APJC',
  AU = 'AU',
  CA = 'CA',
  EU = 'EU',
  GLOBAL = 'GLOBAL',
  IN = 'IN',
  NAM = 'NAM',
  US = 'US',
  ENTERPRISE = 'SCC',
}
