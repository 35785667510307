import { put, select, takeLatest } from 'redux-saga/effects';

import { prometheusAPIClient } from 'src/app/api/clients';
import { IGetIdp } from 'src/app/interfaces/IIdentityProvider';
import { FetchIdentityProviders } from './actions';
import { getEnterpriseId } from '../enterprises/selectors';

export function* getIdp(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;

  try {
    const response: IGetIdp = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/idps`
    );
    const idps = response.data;
    yield put(FetchIdentityProviders.SUCCESS(idps));
  } catch (error) {
    yield put(FetchIdentityProviders.ERROR(error as Error));
  }
}

export function* idpSaga(): Generator {
  yield takeLatest(FetchIdentityProviders.Pending.TYPE, getIdp);
}
