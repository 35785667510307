import { CDSOption } from '@ciscodesignsystems/cds-react-select';
import { GroupsNotificationType } from 'src/app/enums/Groups';
import {
  ICreateGroupParams,
  IUpdateGroupMappingsArgs,
  IRoleAndInstanceIds,
  IAssignUserToGroupArgs,
} from './interfaces';
import { IMapGroupFormRow } from './MapIdentityProvidersGroups/interfaces';
import {
  getActionFactory,
  getAsyncActionFactory,
} from '../../core/redux/utils/action-class-factory';
import { ISortingOptions } from '../../interfaces/ICommon';
import {
  IActionAssignRoleToGroup,
  IActionAssignUser,
  IExistingMappings,
  IFormattedGroupUser,
  IGroup,
  IGroupRole,
  IOption,
  IRolesRows,
  ITablePagination,
} from '../../interfaces/IGroups';
import { IPaginationState, IRole } from '../../interfaces/IUserRoles';
import { INewGroupFields } from '../../interfaces/IUsers';

const createAction = getActionFactory('GROUPS');
const createAsyncAction = getAsyncActionFactory('groups');

/* ----------------- async actions ----------------- */

export const FetchAllGroups = createAsyncAction<null, IGroup[]>(
  'FETCH_ALL_GROUPS'
);

export const FetchAllRoles = createAsyncAction<null, IRole[]>(
  'FETCH_ALL_ROLES'
);

export const CreateGroup = createAsyncAction<ICreateGroupParams, null>(
  'CREATE_GROUP'
);

export const AddGroup = createAsyncAction('ADD_GROUP');

export const FetchGroupUsers = createAsyncAction<null, IFormattedGroupUser[]>(
  'FETCH_GROUP_USERS'
);

export const FetchGroupRoles = createAsyncAction<null, IGroupRole[]>(
  'FETCH_GROUP_ROLES'
);

export const EditGroupName = createAsyncAction('EDIT_GROUP_NAME');

export const AssignRolesToGroup = createAsyncAction('ASSIGN_ROLES_TO_GROUP');

export const AssignRoleToGroup = createAsyncAction<
  IActionAssignRoleToGroup,
  null
>('ASSIGN_ROLE_TO_GROUP');

export const FetchGroupById = createAsyncAction<string, IGroup>(
  'FETCH_GROUP_BY_ID'
);

export const DeleteGroup = createAsyncAction<string, null>('DELETE_GROUP');

export const DeleteAssignedGroupUsers = createAsyncAction<null, null>(
  'DELETE_ASSIGNED_GROUP_USERS'
);

export const DeleteAssignedSingleGroupUser = createAsyncAction<string, null>(
  'DELETE_ASSIGNED_SINGLE_GROUP_USER'
);

export const DeleteAssignedGroupRole = createAsyncAction<null, null>(
  'DELETE_ASSIGNED_GROUP_ROLE'
);

export const AssignUser = createAsyncAction<IActionAssignUser, null>(
  'ASSIGN_USER'
);

export const AssignUsers = createAsyncAction<IAssignUserToGroupArgs, null>(
  'ASSIGN_USERS'
);

export const AssignSelectedInstances = createAsyncAction<null, null>(
  'ASSIGN_SELECTED_INSTANCES'
);

export const AssignSelectedInstance = createAsyncAction<
  { roleAndInstanceIds: IRoleAndInstanceIds; callbackFn?: Function | null },
  null
>('ASSIGN_SELECTED_INSTANCE');

export const SetAssignRolesTableDrawer = createAction<IRolesRows[]>(
  'SET_ASSIGN_ROLES_TABLE_DRAWER'
);

export const ToggleAssignRolesDrawerVisibility = createAction<boolean>(
  'TOGGLE_ASSIGN_ROLES_DRAWER_VISIBILITY'
);

export const ClearEditGroup = createAction('CLEAR_EDIT_GROUP');
/* ----------------- sync actions ------------------ */
export const SetDrawerRolesTable = createAction<IRolesRows[]>(
  'SET_DRAWER_ROLES_TABLE'
);

export const RefreshGroupsPage = createAction('REFRESH_GROUPS_PAGE');

export const SetAssignedRolesTablePagination = createAction<ITablePagination>(
  'SET_ASSIGNED_ROLES_TABLE_PAGINATION'
);

export const SetUsersTablePagination = createAction<ITablePagination>(
  'SET_USERS_TABLE_PAGINATION'
);

export const SetUserTableSelectedRows = createAction<IOption[]>(
  'SET_USER_TABLE_SELECTED_ROWS'
);

export const SetProductFilterOptions = createAction<IOption[]>(
  'SET_PRODUCT_FILTER_OPTIONS'
);

export const SetProductFilterSelectedOption = createAction<IOption>(
  'SET_PRODUCT_FILTER_SELECTED_OPTION'
);

export const SetRegionFilterOptions = createAction<IOption[]>(
  'SET_REGION_FILTER_OPTIONS'
);

export const SetRegionFilterSelectedOption = createAction<IOption>(
  'SET_REGION_FILTER_SELECTED_OPTION'
);

export const SetTypeFilterOptions = createAction<IOption>(
  'SET_TYPE_FILTER_OPTIONS'
);

export const SetTypeFilterSelectedOption = createAction<IOption>(
  'SET_TYPE_FILTER_SELECTED_OPTION'
);

export const SetAssignedRolesTableSearch = createAction<string>(
  'SET_ASSIGNED_ROLES_TABLE_SEARCH'
);

export const SetUsersTableSearch = createAction<string>(
  'SET_USERS_TABLE_SEARCH'
);

export const ClearMapGroupsStepper = createAction('CLEAR_MAP_GROUPS_STEPPER');

export const UpdateGroupsSearchField = createAction<string>(
  'UPDATE_GROUPS_SEARCH_FIELD'
);

export const UpdateTableConfigData = createAction<IPaginationState>(
  'UPDATE_GROUPS_TABLE_CONFIG'
);

export const SetSelectedUsersAddGroupDrawer = createAction<IOption[]>(
  'SET_SELECTED_ADD_GROUP_DRAWER_USERS'
);
export const ToggleGroupsDrawerVisibility = createAction<boolean>(
  'TOGGLE_GROUP_DRAWER_VISIBILITY'
);
export const ChangeNameForNewGroupIdpStepper = createAction<
  Partial<Pick<INewGroupFields, 'name'>>
>('CHANGE_NAME_FOR_NEW_GROUP');

export const ChangeDescriptionForNewGroupIdpStepper = createAction<
  Pick<INewGroupFields, 'description'>
>('CHANGE_DESCRIPTION_FOR_NEW_GROUP');

export const SetMapGroupRowsData = createAction<IMapGroupFormRow[]>(
  'SET_MAP_GROUP_ROWS_DATA'
);

export const UpdateGroupMappings = createAsyncAction<
  IUpdateGroupMappingsArgs,
  null,
  Error
>('UPDATE_GROUP_MAPPINGS');

export const FetchGroupMappings = createAsyncAction<
  null,
  IExistingMappings['data'],
  Error
>('FETCH_GROUP_MAPPINGS');

export const CloseMapGroupsErrorBanner = createAction(
  'CLOSE_MAP_GROUPS_ERROR_BANNER'
);

export const FetchIdpGroups = createAsyncAction<null, any, Error>(
  'FETCH_IDP_GROUPS'
);

export const SetUsersTableSorting = createAction<ISortingOptions | null>(
  'SET_USERS_TABLE_SORTING'
);

export const SetRolesTableSorting = createAction<ISortingOptions | null>(
  'SET_ROLES_TABLE_SORTING'
);

export const SetAddGroupStep = createAction<number>('SET_ADD_GROUP_STEP');

export const SetMapIdentityProviderStep = createAction<number>(
  'SET_MAP_IDENTITY_PROVIDER_STEP'
);

export const AddUsersToGroup = createAction('ADD_USERS_TO_GROUP');

export const EditGroup = createAction<string>('EDIT_GROUP');

export const ShowDeleteGroupModal = createAction('SHOW_DELETE_GROUP_MODAL');

export const HideDeleteGroupModal = createAction('HIDE_DELETE_GROUP_MODAL');

export const ShowAddUsersDrawer = createAction('SHOW_ADD_USERS_DRAWER');

export const HideAddUsersDrawer = createAction('HIDE_ADD_USERS_DRAWER');

export const ShowEditGroupNameDrawer = createAction(
  'SHOW_EDIT_GROUP_NAME_DRAWER'
);

export const HideEditGroupNameDrawer = createAction(
  'HIDE_EDIT_GROUP_NAME_DRAWER'
);

export const ShowDiscardChangesModal = createAction(
  'SHOW_DISCARD_CHANGES_MODAL'
);

export const HideDiscardChangesModal = createAction(
  'HIDE_DISCARD_CHANGES_MODAL'
);

export const ShowAssignRolesDrawer = createAction('SHOW_ASSIGN_ROLES_DRAWER');

export const HideAssignRolesDrawer = createAction('HIDE_ASSIGN_ROLES_DRAWER');

export const SetUsersOptionsFromDrawer = createAction<CDSOption[]>(
  'SET_USER_OPTIONS_FROM_DRAWER'
);

export const SetUsersOptions = createAction<CDSOption[]>('SET_USERS_OPTIONS');

export const ChangeNameField = createAction<CDSOption[]>('CHANGE_NAME_FIELD');

export const AddNotification =
  createAction<GroupsNotificationType>('ADD_NOTIFICATION');

export const HideNotification = createAction('HIDE_NOTIFICATION');

export const ChangeDescriptionField = createAction<Object>(
  'CHANGE_DESCRIPTION_FIELD'
);

export const HideAllEditGroupDrawers = createAction(
  'HIDE_ALL_EDIT_GROUP_DRAWERS'
);

export const DiscardAddGroupChanges = createAction<boolean>(
  'DISCARD_ADD_GROUP_CHANGES'
);

export const SetActiveDeleteGroupId = createAction<string>(
  'SET_ACTIVE_DELETE_GROUP_ID'
);

export const HandleOnDeleteGroupIconClick = createAction<string>(
  'HANDLE_ON_DELETE_GROUP_ICON_CLICK'
);

export const ChangeNameFieldFromDrawer = createAction<Object>(
  'CHANGE_NAME_FIELD_FROM_DRAWER'
);

export const ChangeDescriptionFieldFromDrawer = createAction<Object>(
  'CHANGE_DESCRIPTION_FIELD_FROM_DRAWER'
);

export const SelectActiveDeleteUsers = createAction<string[]>(
  'SELECT_ACTIVE_REMOVE_USERS'
);

export const SelectActiveDeleteSingleUser = createAction<string>(
  'SELECT_ACTIVE_REMOVE_SINGLE_USER'
);

export const SelectActiveDeleteRole = createAction<string>(
  'SELECT_ACTIVE_REMOVE_ROLE'
);

export const ClearAllEditGroupDrawers = createAction(
  'CLEAR_ALL_EDIT_GROUP_DRAWERS'
);

export const HideRemoveModal = createAction('HIDE_REMOVE_MODAL');

export const RefreshEditGroupPage = createAction<string>(
  'REFRESH_EDIT_GROUP_PAGE'
);
