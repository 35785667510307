export enum UserStatus {
  ACTIVE = 'ACTIVE',
  RECOVERY = 'RECOVERY',
  PASSWORD_EXPIRED = 'PASSWORD_EXPIRED',
  STAGED = 'STAGED',
  PROVISIONED = 'PROVISIONED',
  LOCKED_OUT = 'LOCKED_OUT',
  SUSPENDED = 'SUSPENDED',
  DEPROVISIONED = 'DEPROVISIONED',
  ENTERPRISE_DISABLED = 'ENTERPRISE_DISABLED',
}

export enum UIUserStatus {
  ACTIVE = 'Active',
  RECOVERY = 'Recovery',
  PASSWORD_EXPIRED = 'Password Expired',
  STAGED = 'Staged',
  PROVISIONED = 'Provisioned',
  LOCKED_OUT = 'Locked Out',
  SUSPENDED = 'Suspended',
  DEPROVISIONED = 'Deprovisioned',
  ENTERPRISE_DISABLED = 'Disabled',
}
