import React from 'react';
import { BootstrapProvider } from '@cisco-sbg/cspe-mfe-shared-library';
import { createRoot } from 'react-dom/client';
import App from './app/App';

const container = document.getElementById('root');
const root = createRoot(container as Element);
root.render(
  <React.StrictMode>
    <BootstrapProvider
      isStandalone={true}
      appBaseUrl={window.location.origin}
      basepath="/"
      appId="">
      <App />
    </BootstrapProvider>
  </React.StrictMode>
);
