import uniq from 'lodash/uniq';
import { AnyAction, Reducer } from 'redux';
import { matchCRUD, matchPending } from '../../core/redux/utils/action-utils';
import { ResetLoaderState } from '../user-roles/actions';

export const initialState = {
  pending: [],
};

export const loaderReducer: Reducer = (
  state = initialState,
  { type }: AnyAction
) => {
  switch (type) {
    case ResetLoaderState.TYPE:
      return initialState;
    default:
      return matchCRUD(type, (whole: string, entity: string) => {
        if (entity && matchPending(type)) {
          // if crud and matched with pending
          return {
            ...state,
            pending: uniq([...state.pending, type]),
          };
        }

        if (entity && !matchPending(type)) {
          // if crud and not matched with pending
          return {
            ...state,
            pending: state.pending.filter((p: string) => !p.includes(entity)),
          };
        } else {
          // ignore if not matching
          return state;
        }
      });
  }
};
