import { IMapGroupFormRow } from './interfaces';
import { MappingStatuses } from './MappingStatus';

export const MAX_MAP_GROUPS_ROWS = 100;

export const emptyMapGroupsRow: IMapGroupFormRow = {
  idpGroupName: { value: '', errorMessage: '', errorStatus: false },
  sccGroupName: { value: '', selectedOption: null },
  rowStatus: MappingStatuses.Added,
};

export const mapGroupsLocalizationKey = 'groups:mapIdpGroups:secondStep';
