import { createReducer } from 'redux-create-reducer';

import {
  CONFIGURE_PENDING,
  CONFIGURE_SUCCESS,
  CONFIGURE_ERROR,
} from './constants';
import { IConfig } from './interfaces';

export const initialState: IConfig = {
  data: {
    backendUrl: {
      url: '',
    },
    okta: {
      issuer: '',
      clientId: '',
      supportAccessGroupName: '',
    },
    phanesDomain: {
      url: '',
    },
    logForwarderAPIGWEndpoint: {
      url: '',
    },
    loggerConfig: {
      minLevel: '',
      batchInterval: 60000,
      batchLimit: 5,
      disableLogs: false,
    },
  },
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [CONFIGURE_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [CONFIGURE_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    isLoading: false,
  }),
  [CONFIGURE_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
});
