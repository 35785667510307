import { createSelector } from 'reselect';
import { IActivityLogState } from 'src/app/interfaces/IActivityLog';
import { getLogUsersEmails } from './helpers';
import { IState } from '../../core/redux/interfaces';
import 'core-js/features/set';

export const getState = (state: IState): IActivityLogState =>
  state.activityLog || {};

export const getAppliedFilters = createSelector(
  [getState],
  ({ appliedFilters }) => appliedFilters
);

export const getActivityLogData = createSelector(
  [getState],
  ({ activityLogData }) => activityLogData
);

export const getIsActivityLogFilterDrawerVisible = createSelector(
  [getState],
  ({ isActivityLogFilterDrawerVisible }) => isActivityLogFilterDrawerVisible
);

export const getDrawerFilters = createSelector(
  [getState],
  ({ filteredEventsDrawer }) => filteredEventsDrawer
);

export const getLogUsersOptions = createSelector(
  [getState],
  ({ activityLogData, filteredEventsDrawer }) =>
    getLogUsersEmails(
      activityLogData.data.logs,
      filteredEventsDrawer.selectedUsers
    )
);

export const getActivityLogsRequestDate = createSelector(
  [getState],
  ({ activityLogRequestDate }) => {
    if (activityLogRequestDate.length === 0) {
      return {
        date: '',
        time: '',
      };
    }
    return {
      date: activityLogRequestDate.split('T')[0],
      time: activityLogRequestDate.split('T')[1].slice(0, 8),
    };
  }
);
