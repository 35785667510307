import {
  Activated,
  External,
  InActive,
  Legacy,
  Provisioned,
  Trial,
} from './constant';
import { ProvisioningStatus } from '../enums/ProvisioningStatus';
import { IGenericTagProps } from '../interfaces/IProductGenericTag';
import { IProductObjectTransformed } from '../interfaces/IProductList';

export const productFlags = (
  productObject: IProductObjectTransformed
): IGenericTagProps[] => {
  const productTags: IGenericTagProps[] = [];
  const conditionsAndTags = [
    { condition: productObject.isTrial, tag: Trial },
    { condition: productObject.isLegacy, tag: Legacy },
    { condition: !productObject.isActive, tag: InActive },
    {
      condition:
        productObject.isActive &&
        ![ProvisioningStatus.IN_PROGRESS, ProvisioningStatus.ERROR].includes(
          productObject.provisionStatus as ProvisioningStatus
        ),
      tag: Activated,
    },
    {
      condition: [
        ProvisioningStatus.IN_PROGRESS,
        ProvisioningStatus.ERROR,
      ].includes(productObject.provisionStatus as ProvisioningStatus),
      tag: Provisioned,
    },
    { condition: productObject.isExternal, tag: External },
  ];

  conditionsAndTags.forEach(({ condition, tag }) => {
    if (condition) {
      productTags.push(tag);
    }
  });
  return productTags;
};
