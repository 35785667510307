import { put, select, takeLatest } from 'redux-saga/effects';
import { IActionWithPayload } from 'src/app/core/redux/interfaces';
import i18n from 'src/app/i18n';
import { IActivityLogFilters } from 'src/app/interfaces/IActivityLog';
import {
  FetchFilteredActivityLog,
  SetActivityLogRequestDate,
  SetAppliedFilters,
} from './actions';
import { GENERATED_MOCK_ACTIVITY_LOG } from './constants';
import { getAppliedFilters } from './selectors';
import { prometheusAPIClient } from '../../api/clients';
import { getEnterpriseId } from '../enterprises/selectors';

export function* handleSetAppliedFilters(): Generator {
  yield put(FetchFilteredActivityLog.PENDING());
}

export function* handleFetchFilteredActivityLog(
  action: IActionWithPayload<{ toast?: Function }>
): Generator {
  const enterpriseId = yield select(getEnterpriseId);
  const appliedFilters = (yield select(
    getAppliedFilters
  )) as IActivityLogFilters;
  const pageNumber = appliedFilters.pageIndex + 1;
  const currentDate = new Date().toISOString();

  try {
    /* --- This part should be used for integration with BE --- */

    yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/audit-logs?page=${pageNumber}&limit=${appliedFilters.pageSize}`
      // for "parameters" correct work in file "src/app/api/clients.ts"
      // "config.params = null;" should be changed to
      // "config.params.domain = null;"
    );

    yield put(SetActivityLogRequestDate(currentDate));
    // yield put(FetchFilteredActivityLog.SUCCESS(filteredActivityLogData));
    yield put(
      FetchFilteredActivityLog.SUCCESS({
        currentPage: 1,
        totalPages: 4,
        totalRecords: 120,
        logs: GENERATED_MOCK_ACTIVITY_LOG(appliedFilters.pageSize),
      })
    );
    if (action.payload?.toast) {
      yield action.payload.toast({
        id: `${Date.now()}${crypto.randomUUID()}`,
        title: '',
        description: i18n.t('activityLog:successToastText'),
        status: 'positive',
        timeout: 5000,
      });
    }
    /* --- TMP solution - should be removed after integration with BE --- */
  } catch (error) {
    yield put(FetchFilteredActivityLog.ERROR(error as Error));
  }

  // remove this action after backend is ready
  yield put(SetActivityLogRequestDate(currentDate));
  yield put(
    FetchFilteredActivityLog.SUCCESS({
      currentPage: 1,
      totalPages: 4,
      totalRecords: 120,
      logs: GENERATED_MOCK_ACTIVITY_LOG(appliedFilters.pageSize),
    })
  );
}

export function* activityLogSaga(): Generator {
  yield takeLatest(
    FetchFilteredActivityLog.Pending.TYPE,
    handleFetchFilteredActivityLog
  );
  yield takeLatest(SetAppliedFilters.TYPE, handleSetAppliedFilters);
}
