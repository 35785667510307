import { IState } from '../interfaces';

const crudRegEx = /(.*)_(success|pending|error)$/gi;
const pendingRegEx = /(.*)_(pending)$/gi;
const errorRegEx = /(.*)_(error)$/gi;

export const matchCRUD: Function = (actionType: string, callback: Function) =>
  callback.apply(null, crudRegEx.exec(actionType));

export const matchPending: Function = (actionType: string) =>
  pendingRegEx.test(actionType);

export const matchError: Function = (actionType: string) =>
  errorRegEx.test(actionType);

export const getResetStateByKeys = (
  state: IState,
  keys: Array<keyof IState>
): IState => {
  const updatedState = { ...state };
  keys.forEach((key) => {
    // @ts-expect-error
    updatedState[key] = undefined;
  });

  return updatedState;
};
