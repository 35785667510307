import { CDSOption } from '@ciscodesignsystems/cds-react-select';
import { composeFunctions } from './shared';
// -- TODO: Move to shared interfaces
import { IRbacAssignedRole, IRbacGroup } from '../components/users/interfaces';
import { IProduct, IGroupRole } from '../interfaces/IGroups';
import { IRole } from '../interfaces/IRoles';
import { IRbacUserRole } from '../interfaces/IUserRoles';

const extractRolesOptions = (roles: IRole[]): IProduct[] =>
  roles.map((role: IRole) => ({
    label: role.name.replace(/-/g, ' '),
    value: role.id,
    selected: false,
  }));

const extractGroupsOptions = (groups: IRbacGroup[]): IProduct[] =>
  groups.map((role: IRole) => ({
    label: role.name,
    value: role.id,
    selected: false,
  }));

const filterRoleByProduct = (
  selectedProduct: string,
  role: string,
  splitArg: string = ' '
): boolean => {
  const roleRroductName = role.split(splitArg).shift();
  const hasMatch = roleRroductName === selectedProduct;

  return hasMatch;
};

const filterOptionByAssignedRoles = (
  assignedRoles: IGroupRole[],
  option: CDSOption
): boolean => {
  const hasMatch = assignedRoles.find(
    (role: IGroupRole) => role.roleId === option.value
  );
  return !hasMatch;
};

const filterByProduct = (
  allRoles: IRbacUserRole[],
  selectedProduct: string
): IRbacUserRole[] =>
  allRoles.filter((role: IRbacUserRole) =>
    filterRoleByProduct(selectedProduct, role.name || role.roleDisplayName, '-')
  );

const filterByExisting = (
  allRoles: IRbacUserRole[],
  assignedRoles: IRbacAssignedRole[] | IGroupRole[]
): IRbacUserRole[] =>
  allRoles.filter((role: IRbacUserRole) => {
    const hasMatch = assignedRoles.find(
      (assginedRole: IRbacAssignedRole | IGroupRole) =>
        role.id === assginedRole.roleId
    );
    return !hasMatch;
  });

const filterUserRoles = (
  allRoles: IRbacUserRole[],
  assignedRoles: IRbacAssignedRole[],
  selectedProduct: string
): IRbacUserRole[] => {
  const filterBySelectedProduct = (
    allRoles: IRbacUserRole[]
  ): IRbacUserRole[] => filterByProduct(allRoles, selectedProduct);

  const filterByAssignedRoles = (): IRbacUserRole[] =>
    filterByExisting(allRoles, assignedRoles);

  const composedFunction = composeFunctions(
    filterByAssignedRoles,
    filterBySelectedProduct
  ) as Function;

  const output = composedFunction();

  return output;
};

const filterGroupRoles = (
  allRoles: IRbacUserRole[],
  assignedRoles: IGroupRole[],
  selectedProduct: string
): IRbacUserRole[] => {
  const filterBySelectedProduct = (
    allRoles: IRbacUserRole[]
  ): IRbacUserRole[] => filterByProduct(allRoles, selectedProduct);

  const filterByAssignedRoles = (): IRbacUserRole[] =>
    filterByExisting(allRoles, assignedRoles);

  const composedFunction = composeFunctions(
    filterByAssignedRoles,
    filterBySelectedProduct
  ) as Function;

  const output = composedFunction();

  return output;
};

const filterGroups = (
  allGroups: IRbacGroup[],
  assignedGroups: IRbacGroup[]
): IRbacGroup[] => {
  const output = allGroups.filter((group: IRbacGroup) => {
    const hasMatch = assignedGroups.find(
      (assginedGroup: IRbacGroup) => group.id === assginedGroup.groupId
    );
    return !hasMatch;
  });

  return output;
};

const mapPreviouslySelectedOptions = (
  options: CDSOption[],
  optionsFromDrawer: CDSOption[]
): CDSOption[] =>
  options.map((option) =>
    optionsFromDrawer.find(
      (optionFromDrawer) =>
        option.value === optionFromDrawer.value && optionFromDrawer.selected
    )
      ? { ...option, selected: true }
      : option
  );

export {
  extractRolesOptions,
  extractGroupsOptions,
  filterRoleByProduct,
  filterOptionByAssignedRoles,
  filterUserRoles,
  filterGroupRoles,
  filterGroups,
  mapPreviouslySelectedOptions,
};
