import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { IConfig } from '../config/interfaces';
import {
  X_PROMETHEUS_TRACE_ID_RESPONSE_HEADER,
  X_TRACE_ID_RESPONSE_HEADER,
} from '../utils/constant';
import EventEmitter from '../utils/eventEmitter';

type AxiosRequestConfigType =
  | InternalAxiosRequestConfig<any>
  | (Promise<InternalAxiosRequestConfig<any>> & {
      headers: { Authorization: string };
      baseURL: string;
      params: any;
    });

type InjectAuthTokenType = (
  value: InternalAxiosRequestConfig<any>
) => InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>>;

export type OktaJwtPayload = JwtPayload & {
  auth_time: string;
  exp: string;
  uid: string;
};

export const axiosEvent = new EventEmitter();

const formRequestUrl = (url: string): string => `https://${url}`;

const getConfigData = (): IConfig => {
  const persistRoot = JSON.parse(
    localStorage.getItem('persist:root') as string
  );
  return persistRoot ? JSON.parse(persistRoot?.config as string) : '';
};
export const getLogForwarderAPIGWEndpoint = (): string => {
  const { data } = getConfigData();
  return data?.logForwarderAPIGWEndpoint?.url ?? '';
};

const logForwarderAPIGWClient = axios.create({
  baseURL: `${getLogForwarderAPIGWEndpoint()}`,
});

export const getBatchLimit = (): number => {
  const config = getConfigData();
  return config?.data?.loggerConfig.batchLimit ?? 5;
};

export const getBatchInterval = (): number => {
  const config = getConfigData();
  return config?.data?.loggerConfig.batchInterval ?? '';
};

export const getAuthToken: () => string = () => {
  const oktaTokenStorage = JSON.parse(
    localStorage.getItem('okta-token-storage') as string
  );
  return oktaTokenStorage?.accessToken?.accessToken ?? '';
};

export const getUserId: () => string = () => {
  const oktaTokenStorage = JSON.parse(
    localStorage.getItem('okta-token-storage') as string
  );
  return oktaTokenStorage?.accessToken?.claims?.uid ?? '';
};

export const getUserEmail: () => string = () => {
  const oktaTokenStorage = JSON.parse(
    localStorage.getItem('okta-token-storage') as string
  );
  return oktaTokenStorage?.accessToken?.claims?.sub ?? '';
};

export const getPhanesBaseURL = (): string => {
  const { data } = getConfigData();
  const phanesBaseURL = data?.phanesDomain?.url;
  return phanesBaseURL ? formRequestUrl(phanesBaseURL) : '';
};

export const getPrometheusBaseURL = (): string => {
  const { data } = getConfigData();
  const prometheusBaseURL = data?.backendUrl?.url ?? '';
  return prometheusBaseURL ? formRequestUrl(prometheusBaseURL) : '';
};

const phanesAPIClient = axios.create({
  baseURL: getPhanesBaseURL(),
  params: {
    domain: 'phanes',
  },
});

const prometheusAPIClient = axios.create({
  baseURL: getPrometheusBaseURL(),
  params: {
    domain: 'prometheus',
  },
});

const apiClients = [phanesAPIClient, prometheusAPIClient];

export const injectRequestData: InjectAuthTokenType = async (
  config: AxiosRequestConfigType
) => {
  const authToken = getAuthToken();
  if (!authToken) {
    return await config;
  }
  if (config.baseURL?.length === 0) {
    const { data: configData } = await axios.get('/config/config.json');
    if (config.params?.domain === 'phanes') {
      config.baseURL = formRequestUrl(configData.phanesDomain.url);
    } else {
      config.baseURL = formRequestUrl(configData.backendUrl.url);
    }
  }
  config.params = null;
  config.headers.Authorization = `Bearer ${authToken}`;
  return await config;
};

export const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};
export const onResponseError = async (error: any): Promise<void> => {
  if (error?.response?.status === 401) {
    const loggerObject = getLoggerObjectFromError(error);
    axiosEvent.emit('axios_401', loggerObject);
  }
  throw error;
};

export const getLoggerObjectFromError = (error: AxiosError): any => {
  const originalRequest = error.config ?? ({} as AxiosRequestConfig);
  const decodedJwt: OktaJwtPayload = jwtDecode(
    (originalRequest.headers?.Authorization ?? '')?.split(' ')[1]
  );
  const { auth_time = '', exp, uid = '' } = decodedJwt;
  const currentTimeStamp = Date.now();
  const traceId: string =
    error?.response?.headers[X_TRACE_ID_RESPONSE_HEADER] ??
    error?.response?.headers[X_PROMETHEUS_TRACE_ID_RESPONSE_HEADER] ??
    '';
  return {
    auth_time,
    exp,
    uid,
    currentTimeStamp,
    traceId,
  };
};

apiClients.forEach((apiClient) => {
  apiClient.interceptors.request.use(injectRequestData);
  apiClient.interceptors.response.use(onResponse, onResponseError);
});

export { phanesAPIClient, prometheusAPIClient, logForwarderAPIGWClient };
