import uniqBy from 'lodash/uniqBy';
import { AnyAction, Reducer } from 'redux';

import { IErrorItem } from './interfaces';
import { matchCRUD, matchError } from '../../core/redux/utils/action-utils';

export const initialState = {
  allErrors: [],
};

export const errorsReducer: Reducer = (
  state = initialState,
  { type, payload }: AnyAction
) =>
  matchCRUD(type, (whole: string, entity: string) => {
    if (entity && matchError(type) && payload) {
      // if crud and matched with error
      return {
        ...state,
        allErrors: uniqBy(
          [...state.allErrors, { type, error: payload }],
          [type]
        ),
      };
    }

    if (entity && !matchError(type)) {
      // if crud and not matched with error
      return {
        ...state,
        allErrors: state.allErrors.filter(
          (p: IErrorItem) => !p.type.includes(entity)
        ),
      };
    } else {
      // ignore if not matching
      return state;
    }
  });
