import React from 'react';
import { CDSFlex } from '@ciscodesignsystems/cds-react-flex';
import { CDSSpinner } from '@ciscodesignsystems/cds-react-spinner';

const CommonLoader: React.FC = () => {
  return (
    <>
      <CDSFlex
        justify="center"
        align="center">
        <CDSSpinner
          size="lg"
          data-testid="spinner"
        />
      </CDSFlex>
    </>
  );
};

export default CommonLoader;
