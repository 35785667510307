import { AnyAction } from 'redux';
import { createReducer } from 'redux-create-reducer';

import { IIdp } from 'src/app/interfaces/IIdentityProvider';
import { FetchIdentityProviders } from './actions';

export const initialState: IIdp = {
  idp: {
    data: [],
    error: null,
    loading: false,
  },
};

export default createReducer(initialState, {
  [FetchIdentityProviders.Pending.TYPE]: (state: IIdp) => ({
    ...state,
    idp: {
      data: [],
      loading: true,
      error: null,
    },
  }),

  [FetchIdentityProviders.Success.TYPE]: (
    state: IIdp,
    { payload }: AnyAction
  ) => ({
    ...state,
    idp: {
      data: payload,
      loading: false,
      error: null,
    },
  }),

  [FetchIdentityProviders.Error.TYPE]: (
    state: IIdp,
    { payload }: AnyAction
  ) => ({
    ...state,
    idp: {
      data: [],
      error: payload,
      loading: false,
    },
  }),
});
