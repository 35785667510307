type StringMap = Record<string, any[]>;
export default class EventEmitter {
  events: StringMap;
  constructor() {
    this.events = {};
  }

  on(eventName: string, cb: any): void {
    // registers events
    if (this.events[eventName] !== undefined) {
      this.events[eventName].push(cb);
    } else {
      this.events[eventName] = [cb];
    }
  }

  emit(eventName: string, rest: any): void {
    // rest recieves args an array
    if (this.events[eventName] !== undefined) {
      this.events[eventName].forEach((cb) => {
        cb(rest);
      });
    }
  }

  deleteEvent(eventName: string): void {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete this.events[eventName];
  }
}
