import { CDSOption } from '@ciscodesignsystems/cds-react-select';
import _toUpper from 'lodash/toUpper';
import uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';
import {
  convertFeatureFlagsToSet,
  getProvisionedProductInstances,
} from './helpers';
import { IState } from '../../core/redux/interfaces';
import { EnterpriseRegionType } from '../../enums/EnterpriseType';
import { ProvisioningStatus } from '../../enums/ProvisioningStatus';
import { IProductInstance, ICommon } from '../../interfaces/ICommon';
import 'core-js/features/set';
import {
  ENTERPRISE_PRODUCT_TYPE,
  ENTERPRISE,
  FEATURE_FLAG_PREFIX,
} from '../../utils/constant';

export const getState = (state: IState): ICommon => state.common || {};

export const getBootstrapConfig = createSelector(
  [getState],
  ({ bootstrap }) => bootstrap
);

export const getNavigation = createSelector(
  [getState],
  ({ navigation }) => navigation
);

export const getEnterpriseId = createSelector(
  [getNavigation],
  ({ enterpriseId }) => enterpriseId
);

export const getAuth = createSelector([getState], ({ auth }) => auth);

export const getAllProductsInstances = createSelector(
  [getState],
  ({ productInstances }) => {
    const SCCProductInstance = {
      productType: ENTERPRISE_PRODUCT_TYPE,
      productName: ENTERPRISE,
      provisioningStatus: ProvisioningStatus.FINISHED,
      id: ENTERPRISE_PRODUCT_TYPE,
      region: {
        name: EnterpriseRegionType.ENTERPRISE,
        description: ENTERPRISE,
      },
    };

    return {
      ...productInstances,
      items: [...productInstances.items, SCCProductInstance],
    };
  }
);

export const getAllProvisionedProductsInstances = createSelector(
  [getAllProductsInstances],
  ({ items: allProductInstances }) =>
    getProvisionedProductInstances(allProductInstances)
);

export const getAllRegions = createSelector(
  [getAllProductsInstances],
  ({ items }) =>
    items.reduce(
      (
        acc: CDSOption[],
        { region: { name, description } }: IProductInstance
      ) => [...acc, { value: name, label: description }],
      []
    )
);

export const getUniqueRegions = createSelector([getAllRegions], (allRegions) =>
  uniqBy(allRegions, 'value')
);

export const getAllUsers = createSelector(
  [getState],
  ({ allUsers }) => allUsers
);

export const getCurrentUserId = createSelector(
  [getState],
  ({ auth }) => auth.userId
);

export const getFeatureFlagsSet = createSelector(
  [(state) => state.common.featureFlags],
  (currentFeatureFlags) => {
    const currentFeatureFlagsSet =
      convertFeatureFlagsToSet(currentFeatureFlags);
    return currentFeatureFlagsSet;
  }
);

export const hasFeatureFlags = createSelector(
  [getFeatureFlagsSet, (state, featureFlags: string[]) => featureFlags],
  (currentFeatureFlagsSet, flags) => {
    const uppercaseFlags = flags?.map((flag) =>
      _toUpper(flag).replace(FEATURE_FLAG_PREFIX, '')
    );
    const uppercaseFlagsSet = new Set(uppercaseFlags);
    return uppercaseFlagsSet.isSubsetOf(currentFeatureFlagsSet);
  }
);
