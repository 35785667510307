import { phanesAPIClient } from '../api/clients';
import { ComponentType } from '../enums/ProvisioningFieldTypes';
import {
  IProductTransformed,
  IProductDTO,
  IProductListErrorResponse,
  IProductListSuccessResponse,
  IProductTransformedData,
  IProductObjectTransformed,
} from '../interfaces/IProductList';
import { productFlags } from '../utils/productVisibleTags';
export const fetchProductList = async (
  enterpriseId: string,
  featureFlagExist: boolean = false
): Promise<IProductListSuccessResponse | IProductListErrorResponse> => {
  try {
    const response: IProductDTO = await phanesAPIClient.get(
      `/v1/customers?enterprise_id=${enterpriseId}`
    );
    let productTransformedData: IProductTransformedData = {
      allProducts: [],
      pendingProducts: [],
      otherProducts: [],
    };

    if (response.data) {
      productTransformedData = response.data.reduce(
        (finalData: IProductTransformedData, product) => {
          let provisioning_metadata =
            product?.product?.provisioning_metadata ?? [];

          if (product.product.provisioning_questions) {
            provisioning_metadata = product.product?.provisioning_questions.map(
              (provisioningQuestion) => {
                let questionValue = '';
                if (
                  provisioningQuestion.type === ComponentType.DROP_DOWN &&
                  provisioningQuestion.options.length === 1
                ) {
                  questionValue = provisioningQuestion.options[0].value;
                }
                return {
                  name: provisioningQuestion.name,
                  value: questionValue,
                };
              }
            );
          }

          const productEntitlements =
            product?.org_entitlement_instances ?? product.entitlement_instances;
          const productObject: IProductObjectTransformed = {
            productId: product.ID,
            activationCode: product.activationCode,
            instanceId: product?.external_tenant_id,
            name: product.product.name,
            provisionStatus: product.provisioning_status,
            region: product.region,
            isTrial: product?.is_trial ?? false,
            isActive: product?.isActive ?? true, // default all products are active.
            isHidden: product?.isHidden ?? false,
            isLegacy: product?.isLegacy ?? false,
            isEA: product?.isEA ?? false,
            productAllowedRegions: product?.product?.product_allowed_regions,
            initialAdmin: product?.initialAdmin ?? '',
            productTags: [],
            provisioningQuestionsMetaData: provisioning_metadata,
            provisioningQuestions: product.product.provisioning_questions ?? [],
          };

          productObject.productTags = productFlags(productObject);
          const finalProductObject: IProductTransformed[] = [];
          if (
            featureFlagExist &&
            (product?.org_entitlement_instances ?? []).length > 1 &&
            product?.provisioning_status === 'FINISHED'
          ) {
            for (const productEntitlement of productEntitlements) {
              const subscriptionData = product.is_trial
                ? productEntitlement.trial
                : productEntitlement.subscription;

              finalProductObject.push({
                ...productObject,
                name:
                  (!productEntitlement?.entitlement?.is_service
                    ? productEntitlement?.entitlement?.description
                    : product.product.name) ?? '',
                subscriptionId: subscriptionData?.externalId || '-',
                isService: productEntitlement.entitlement.is_service
                  ? productEntitlement.entitlement.is_service
                  : false,
                startDate:
                  (product.is_trial
                    ? subscriptionData.trial_start_date
                    : subscriptionData.requested_start_date) ?? '',
                expiryDate:
                  (product.is_trial
                    ? subscriptionData.trial_end_date
                    : subscriptionData.subscription_end_date) ?? '',
                isExternal: subscriptionData.is_external ?? false,
                productName: subscriptionData.product_name ?? '',
                canProvision: subscriptionData.is_provisionable ?? false,
                seat: productEntitlement?.quantity ?? '-',
                tier: productEntitlement?.entitlement?.value ?? '',
                productDescription:
                  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                  productEntitlement?.entitlement?.description || '-',
              });
            }
          } else {
            const filteredEntitlements = productEntitlements.filter(
              (productEntitlement) =>
                productEntitlement?.entitlement?.description !==
                'No Description - Entitlement'
            );
            const subscriptionData = product.is_trial
              ? productEntitlements[0].trial
              : productEntitlements[0].subscription;
            finalProductObject.push({
              ...productObject,
              subscriptionId: subscriptionData?.externalId || '-',
              startDate:
                (product.is_trial
                  ? subscriptionData.trial_start_date
                  : subscriptionData.requested_start_date) ?? '',
              expiryDate:
                (product.is_trial
                  ? subscriptionData.trial_end_date
                  : subscriptionData.subscription_end_date) ?? '',
              isExternal: subscriptionData.is_external ?? false,
              productName: subscriptionData.product_name ?? '',
              canProvision: subscriptionData.is_provisionable ?? false,
              seat: productEntitlements[0]?.quantity ?? '-',
              tier: productEntitlements[0]?.entitlement?.value ?? '',
              productDescription:
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                filteredEntitlements[0]?.entitlement?.description || '-',
              isService: productEntitlements[0].entitlement.is_service
                ? productEntitlements[0].entitlement.is_service
                : false,
            });
          }
          if (featureFlagExist) {
            finalData[
              productObject.provisionStatus === 'FINISHED'
                ? 'otherProducts'
                : 'pendingProducts'
            ].push(...finalProductObject);
          } else {
            finalData[
              productObject.provisionStatus === 'NEW' ||
              productObject.provisionStatus === 'ACTION_REQUIRED'
                ? 'pendingProducts'
                : 'otherProducts'
            ].push(...finalProductObject);
          }
          finalData.allProducts.push(...finalProductObject);
          return finalData;
        },
        {
          allProducts: [],
          pendingProducts: [],
          otherProducts: [],
        }
      );
    }

    return {
      isOk: true,
      data: productTransformedData,
      error: null,
    };
  } catch (error) {
    return {
      isOk: false,
      data: null,
      error,
    };
  }
};
