import {
  IActivityLog,
  DateRangePickerValue,
} from 'src/app/interfaces/IActivityLog';
import { IOption } from '../groups/interfaces';

export const getLogUsersEmails = (
  activityLogData: IActivityLog[],
  selectedUsers: string[]
): IOption[] => {
  if (!activityLogData) return [];
  return activityLogData.map((log: IActivityLog) => {
    const isSelected = selectedUsers.includes(log.user);
    return {
      label: log.user,
      value: log.user,
      selected: isSelected,
    };
  });
};

export const formatDateToMMDDYYYY = (dateInput: string | Date): string => {
  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;

  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };

  return date.toLocaleDateString('en-US', options);
};

export function getInitialDefaultRange(createdAt: string): [Date, Date] {
  const createdAtDate = new Date(createdAt);
  const endDate = new Date();

  const ninetyDaysAgo = new Date();
  ninetyDaysAgo.setDate(endDate.getDate() - 90);

  const startDate =
    createdAtDate < ninetyDaysAgo ? ninetyDaysAgo : createdAtDate;

  return [startDate, endDate];
}

export function parseDateRangePickerValue(
  initialFetchedRange: DateRangePickerValue
): DateRangePickerValue {
  if (initialFetchedRange && initialFetchedRange.length === 2) {
    return [
      typeof initialFetchedRange[0] === 'string'
        ? new Date(initialFetchedRange[0])
        : initialFetchedRange[0],
      typeof initialFetchedRange[1] === 'string'
        ? new Date(initialFetchedRange[1])
        : initialFetchedRange[1],
    ];
  }
  return initialFetchedRange === null ? null : undefined;
}
