import {
  IProductRole,
  IRbacUserRole,
  IRolesRowsSummery,
  ISummaryRole,
  IRoleSummery,
} from '../../interfaces/IUserRoles';
import { IDependentOption, IOption, IRolesRows } from '../users/interfaces';
export const rowChangeProductOptionsToSelected = (
  assignRolesTable: IRolesRows[],
  rolesOptions: IDependentOption,
  selectedOption: IOption,
  currentRow: number
): IRolesRows[] =>
  assignRolesTable.map((row, indexRow) => {
    if (indexRow === currentRow) {
      const updatedProductsOptions = row.productsOptions.map((option) => ({
        ...option,
        selected: option.value === selectedOption.value,
      }));
      return {
        ...row,
        selectedProduct: selectedOption.value,
        productsOptions: updatedProductsOptions,
        rolesOptions,
      };
    }
    const filteredProductsOptions = row.productsOptions.filter(
      (option) => option.value !== selectedOption.value
    );

    return {
      ...row,
      productsOptions: filteredProductsOptions,
    };
  });

export const rowChangeRoleOptionsToSelected = (
  assignRolesTable: IRolesRows[],
  rolesOptions: IDependentOption,
  selectedOptionsMap: Map<string, IOption>,
  currentRow: number
): IRolesRows[] =>
  assignRolesTable.map((row, indexRow) => {
    if (indexRow === currentRow) {
      return {
        ...row,
        rolesOptions: updateRolesOptionsSelected(
          rolesOptions,
          selectedOptionsMap
        ),
      };
    }
    return row;
  });

export const updateRolesOptionsSelected = (
  rolesOptions: IDependentOption,
  selectedOptionsMap: Map<string, IOption>
): IDependentOption =>
  Object.fromEntries(
    Object.entries(rolesOptions).map(
      ([productName, availableRoles]: IDependentOption) => {
        const updatedRoles = availableRoles.map((parentOption: IOption) => {
          return selectedOptionsMap.get(parentOption.value) ?? parentOption;
        });
        return [productName, updatedRoles];
      }
    )
  );

export const toProductOptions = (rbacRoleList: IRbacUserRole[]): IOption[] => {
  const uniqueElements = Array.from(
    new Set(rbacRoleList.map(({ product }: IRbacUserRole) => product))
  );
  return uniqueElements.map((productName) => ({
    value: productName,
    label: productName,
    selected: false,
  }));
};

const transformRoleName = (name: string): string => {
  if (!name) return '';
  return name.replace(/(?!^)([A-Z])(?=[a-z])/g, ' $1').trim();
};

export const toRoleOptions = (
  rbacRoleList: IRbacUserRole[]
): IDependentOption => {
  return rbacRoleList.reduce<IDependentOption>(
    (res, { id, roleDisplayName, type, product }) => {
      const readableRole = roleDisplayName
        ? transformRoleName(roleDisplayName)
        : '';
      if (!res[product]) res[product] = [];
      if (readableRole) {
        res[product].push({
          value: id,
          label: readableRole,
          selected: false,
          type,
        });
      }
      return res;
    },
    {}
  );
};

export const extractSelectedRoles = (data: IRolesRows[]): IProductRole[] => {
  const roles: IProductRole[] = [];
  data.forEach((entry) => {
    if (entry.selectedProduct) {
      const productRoles = entry.rolesOptions[entry.selectedProduct] || [];
      productRoles.forEach(
        (role: { selected: boolean; value: string; type: string }) => {
          if (role.selected) {
            roles.push({ roleId: role.value, roleType: role.type || 'STATIC' });
          }
        }
      );
    }
  });
  return roles;
};

export const summaryTableRoles = (
  data: IRolesRowsSummery[]
): ISummaryRole[] => {
  return data
    .filter(
      (
        item
      ): item is {
        selectedProduct: string;
        rolesOptions: Record<string, IRoleSummery[]>;
      } => item.selectedProduct !== null && item.rolesOptions !== undefined
    )
    .map((item) => {
      const roles = item.rolesOptions[item.selectedProduct].filter(
        (role) => role.selected
      );
      return { product: item.selectedProduct, roles };
    });
};
