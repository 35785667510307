import { sortBy } from 'lodash';
import { AnyAction } from 'redux';
import { createReducer } from 'redux-create-reducer';
import {
  AddUserRoleNotification,
  AssignUserRolesRelations,
  AssignUserRolesRelationsResetToastState,
  ChangeProductField,
  ChangeTextField,
  ClearAssignUserRolesDrawerData,
  ClearCustomRoleStepper,
  ClearEditingUserRole,
  ClearRolesTableConfigData,
  ClearSelectedUserRoles,
  CreateCustomRole,
  DeleteUserRole,
  DiscardCustomRoleStepper,
  FetchAllGroups,
  FetchCustomRole,
  FetchProductPermissions,
  FetchProductsCustomRole,
  FetchRoleToGroupsRelations,
  FetchRoleToUsersRelations,
  FetchUserRoles,
  HideUserRoleNotification,
  SetActiveEditCustomRoleId,
  SetAssignUserRolesDrawerData,
  SetCustomRoleDirty,
  SetCustomRoleStepperStep,
  SetDeleteCustomUserRole,
  SetEditingUserRole,
  SetPermissionTableSearch,
  SetPermissionTableSelectedRows,
  SetPermissionTableSelectedScopes,
  SetRolesTableConfigData,
  SetSelectedUserRoles,
  ToggleDeleteUserRoleModal,
  ToggleDiscardChangesModal,
  ToggleSummaryStepperBanner,
  UnassignGroupFromRole,
  UnassignGroupFromRoleResetToastState,
  UnassignUserFromRole,
  UnassignUserFromRoleResetToastState,
  UpdateCustomRole,
  ClearSelectedUserRolesToastData,
  setSelectedAppPermissions,
  FetchApplicationScopes,
  SetBundledRoles,
  SetProductsOptions,
  SetRolesOptions,
} from './actions';
import {
  IAssignUserRolesDrawerData,
  IUser,
  IUserRoleResp,
  IUserRolesState,
} from '../../interfaces/IUserRoles';
import { TABLE_CONFIG } from '../../utils/constant';

export const initialState: IUserRolesState = {
  allGroups: {
    items: [],
    loading: false,
    error: null,
  },
  selectedUserRoles: {
    items: [],
    toastData: null,
    loading: false,
    error: null,
  },
  rolesTableConfigData: {
    filteredUserRoles: [],
    productFilter: null,
    typeFilter: null,
    availableProducts: [],
    availableTypes: [],
    globalSearch: '',
    currentSorting: [
      {
        id: 'roleDisplayName',
        desc: false,
      },
    ],
    pagination: {
      pageIndex: 0,
      pageSize: TABLE_CONFIG.paginationConfig.pageSize,
    },
    selectedRolesRows: {},
  },
  editingUserRole: {
    id: '',
    product: '',
    roleDisplayName: '',
    delegatable: false,
    assignUsersSearchField: '',
    assignUsersPagination: { pageIndex: 0, pageSize: 30 },
    assignUsersSortingOptions: null,
    assignGroupsSearchField: '',
    assignGroupsPagination: { pageIndex: 0, pageSize: 30 },
    assignGroupsSortingOptions: null,
    assignPermissionsSearchField: '',
    assignProductRolesSearchField: '',
    featureFlags: [],
  },
  assignUserRolesDrawerData: {
    currentStep: 0,
    drawerUserOptions: [],
    drawerGroupOptions: [],
    selectedInstancesIds: {},
  },
  unassignUserFromRole: {
    loading: false,
    toastData: null,
    error: null,
  },
  unassignGroupFromRole: {
    loading: false,
    toastData: null,
    error: null,
  },
  userRoles: {
    items: [],
    loading: false,
    error: null,
  },
  roleToUsersRelations: {
    items: [],
    loading: false,
    error: null,
  },
  roleToGroupsRelations: {
    items: [],
    loading: false,
    error: null,
  },
  notification: {
    type: null,
    data: null,
    options: {},
  },
  customRoles: {
    isDeleteUserRoleModalVisible: false,
    productPermissions: [],
    applicationScopes: [],
    isDiscardChangesModalVisible: false,
    isLoading: false,
    error: null,
    permissionTableSearch: '',
    permissionTableSelectedRows: {},
    permissionTableSelectedScopes: {},
    activeEditCustomRoleId: null,
    fetchedEditCustomRole: null,
    toggleSummaryStepperBanner: false,
    isCustomRoleDirty: false,
    customRoleProducts: [],
    deleteCustomUserRole: {
      isLoading: false,
      error: null,
      id: '',
      product: '',
      roleDisplayName: '',
      assignUsersSearchField: '',
      assignUsersPagination: { pageIndex: 0, pageSize: 30 },
      assignGroupsSearchField: '',
      assignGroupsPagination: { pageIndex: 0, pageSize: 30 },
      assignPermissionsSearchField: '',
    },
    stepper: {
      stepperStep: null,
      productOptions: {
        items: [],
        isLoading: false,
        error: null,
      },
      productField: {
        value: null,
        touched: false,
        focused: false,
        error:
          'userRoles:customRoleStepper.roleDetailsStep.errorMessages.emptyProduct',
      },
      roleNameField: {
        value: '',
        touched: false,
        focused: false,
        error:
          'userRoles:customRoleStepper.roleDetailsStep.errorMessages.emptyRoleName',
      },
      roleDescriptionField: {
        value: '',
        touched: false,
        focused: false,
        error: '',
      },
      productCapabilities: {
        items: [
          {
            productScope: {
              items: { options: [], label: '' },
              error: null,
              isLoading: false,
              touched: false,
              focused: false,
            },
            productPermission: {
              items: [],
              error: null,
              isLoading: false,
              touched: false,
              focused: false,
            },
          },
        ],
        error: '',
        loading: false,
      },
    },
  },
  bundledRoles: {
    productsOptions: [],
    rolesOptions: {},
    bundledRolesData: [
      {
        selectedProduct: null,
        productsOptions: [],
        rolesOptions: {},
      },
    ],
  },
};

export default createReducer(initialState, {
  [FetchUserRoles.Pending.TYPE]: (state: IUserRolesState) => ({
    ...state,
    userRoles: {
      ...state.userRoles,
      loading: true,
    },
  }),
  [FetchUserRoles.Success.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => {
    const sortedUserRolesItems: IUserRoleResp[] = sortBy(payload, [
      'roleDisplayName',
    ]);

    return {
      ...state,
      userRoles: {
        ...state.userRoles,
        items: sortedUserRolesItems,
        loading: false,
      },
    };
  },
  [FetchUserRoles.Error.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    userRoles: {
      ...state.userRoles,
      items: [],
      loading: false,
      error: payload,
    },
  }),

  [FetchAllGroups.Pending.TYPE]: (state: IUserRolesState) => ({
    ...state,
    allGroups: {
      ...state.allGroups,
      loading: true,
      error: null,
    },
  }),
  [FetchAllGroups.Success.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    allGroups: {
      ...state.allGroups,
      items: payload,
      loading: false,
      error: null,
    },
  }),
  [FetchAllGroups.Error.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    allGroups: {
      ...state.allGroups,
      items: [],
      loading: false,
      error: payload,
    },
  }),

  [FetchRoleToUsersRelations.Pending.TYPE]: (state: IUserRolesState) => ({
    ...state,
    roleToUsersRelations: {
      ...state.roleToUsersRelations,
      loading: true,
      error: null,
    },
  }),
  [FetchRoleToUsersRelations.Success.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    roleToUsersRelations: {
      ...state.roleToUsersRelations,
      items: payload,
      loading: false,
      error: null,
    },
  }),
  [FetchRoleToUsersRelations.Error.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    roleToUsersRelations: {
      ...state.roleToUsersRelations,
      items: [],
      loading: false,
      error: payload,
    },
  }),

  [UnassignUserFromRole.Pending.TYPE]: (state: IUserRolesState) => ({
    ...state,
    unassignUserFromRole: {
      ...state.unassignUserFromRole,
      loading: true,
      toastData: null,
      error: null,
    },
  }),
  [UnassignUserFromRole.Success.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    unassignUserFromRole: {
      ...state.unassignUserFromRole,
      loading: false,
      toastData: payload,
      error: null,
    },
  }),
  [UnassignUserFromRole.Error.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    unassignUserFromRole: {
      ...state.unassignUserFromRole,
      loading: false,
      error: payload,
      toastData: null,
    },
  }),
  [UnassignUserFromRoleResetToastState.TYPE]: (state: IUserRolesState) => ({
    ...state,
    unassignUserFromRole: {
      ...state.unassignUserFromRole,
      toastData: null,
    },
  }),

  [UnassignGroupFromRole.Pending.TYPE]: (state: IUserRolesState) => ({
    ...state,
    unassignGroupFromRole: {
      ...state.unassignGroupFromRole,
      loading: true,
      toastData: null,
      error: null,
    },
  }),
  [UnassignGroupFromRole.Success.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    unassignGroupFromRole: {
      ...state.unassignGroupFromRole,
      loading: false,
      toastData: payload,
      error: null,
    },
  }),
  [UnassignGroupFromRole.Error.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    unassignGroupFromRole: {
      ...state.unassignGroupFromRole,
      loading: false,
      error: payload,
      toastData: null,
    },
  }),
  [UnassignGroupFromRoleResetToastState.TYPE]: (state: IUserRolesState) => ({
    ...state,
    unassignGroupFromRole: {
      ...state.unassignGroupFromRole,
      toastData: null,
    },
  }),

  [FetchRoleToGroupsRelations.Pending.TYPE]: (state: IUserRolesState) => ({
    ...state,
    roleToGroupsRelations: {
      ...state.roleToGroupsRelations,
      loading: true,
      error: null,
    },
  }),
  [FetchRoleToGroupsRelations.Success.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    roleToGroupsRelations: {
      ...state.roleToGroupsRelations,
      items: payload,
      loading: false,
      error: null,
    },
  }),
  [FetchRoleToGroupsRelations.Error.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    roleToGroupsRelations: {
      ...state.roleToGroupsRelations,
      loading: false,
      error: payload,
    },
  }),

  [AssignUserRolesRelations.Pending.TYPE]: (state: IUserRolesState) => ({
    ...state,
    selectedUserRoles: {
      ...state.selectedUserRoles,
      loading: true,
      error: null,
    },
  }),
  [AssignUserRolesRelations.Success.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => {
    const {
      selectedGroupsAmount,
      selectedUsersAmount,
      hasErrors,
      rolesNamesText,
    } = payload;
    const { selectedUserRoles } = state;

    return {
      ...state,
      selectedUserRoles: {
        ...state.selectedUserRoles,
        items: [],
        toastData: {
          selectedRolesCount: selectedUserRoles.items.length,
          groupsAmount: selectedGroupsAmount,
          usersAmount: selectedUsersAmount,
          hasErrors,
          rolesNamesText,
        },
        loading: false,
        error: null,
      },
      assignUserRolesDrawerData: {
        ...initialState.assignUserRolesDrawerData,
      },
    };
  },
  [AssignUserRolesRelations.Error.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    selectedUserRoles: {
      ...state.selectedUserRoles,
      loading: false,
      error: payload,
    },
  }),
  [AssignUserRolesRelationsResetToastState.TYPE]: (state: IUserRolesState) => ({
    ...state,
    selectedUserRoles: {
      ...state.selectedUserRoles,
      toastData: null,
    },
  }),

  [SetSelectedUserRoles.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    selectedUserRoles: {
      ...state.selectedUserRoles,
      items: payload,
    },
  }),
  [ClearSelectedUserRoles.TYPE]: (state: IUserRolesState) => ({
    ...state,
    rolesTableConfigData: {
      ...state.rolesTableConfigData,
      selectedRolesRows: {},
      productFilter: null,
      typeFilter: null,
      globalSearch: '',
    },
    selectedUserRoles: {
      ...state.selectedUserRoles,
      items: [],
      toastData: null,
    },
  }),
  [ClearSelectedUserRolesToastData.TYPE]: (state: IUserRolesState) => ({
    ...state,
    selectedUserRoles: {
      ...state.selectedUserRoles,
      toastData: null,
    },
  }),

  [SetEditingUserRole.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    editingUserRole: {
      ...state.editingUserRole,
      ...payload,
    },
  }),
  [ToggleDiscardChangesModal.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      isDiscardChangesModalVisible: payload,
    },
  }),

  [SetRolesTableConfigData.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    rolesTableConfigData: {
      ...state.rolesTableConfigData,
      ...payload,
    },
  }),
  [ClearRolesTableConfigData.TYPE]: (state: IUserRolesState) => ({
    ...state,
    rolesTableConfigData: {
      ...initialState.rolesTableConfigData,
    },
    selectedUserRoles: {
      ...initialState.selectedUserRoles,
    },
  }),

  [SetAssignUserRolesDrawerData.TYPE]: (
    state: IUserRolesState,
    { payload }: { payload: Partial<IAssignUserRolesDrawerData> }
  ) => ({
    ...state,
    assignUserRolesDrawerData: {
      ...state.assignUserRolesDrawerData,
      ...payload,
    },
  }),
  [ClearAssignUserRolesDrawerData.TYPE]: (state: IUserRolesState) => ({
    ...state,
    assignUserRolesDrawerData: {
      currentStep: 0,
      drawerUserOptions: [],
      drawerGroupOptions: [],
      selectedInstancesIds: {},
    },
  }),

  [ClearEditingUserRole.TYPE]: (state: IUserRolesState) => ({
    ...state,
    editingUserRole: {
      ...initialState.editingUserRole,
      roleDescription: undefined,
    },
  }),
  [ToggleDeleteUserRoleModal.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      isDeleteUserRoleModalVisible: payload,
    },
  }),
  [AddUserRoleNotification.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    notification: {
      type: payload.type,
      data: payload.roleName || null,
      options: payload.options || {},
    },
  }),
  [HideUserRoleNotification.TYPE]: (state: IUserRolesState) => ({
    ...state,
    notification: {
      ...initialState.notification,
    },
  }),
  [SetDeleteCustomUserRole.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      deleteCustomUserRole: {
        ...state.customRoles.deleteCustomUserRole,
        ...payload,
      },
    },
  }),
  [DeleteUserRole.Pending.TYPE]: (state: IUserRolesState) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      deleteCustomUserRole: {
        ...state.customRoles.deleteCustomUserRole,
        isLoading: true,
      },
    },
  }),
  [DeleteUserRole.Success.TYPE]: (state: IUserRolesState) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      deleteCustomUserRole: {
        ...state.customRoles.deleteCustomUserRole,
        isLoading: false,
      },
      isDeleteUserRoleModalVisible: false,
    },
  }),
  [DeleteUserRole.Error.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      deleteCustomUserRole: {
        ...state.customRoles.deleteCustomUserRole,
        isLoading: false,
        error: payload,
      },
    },
  }),

  [FetchProductsCustomRole.Pending.TYPE]: (state: IUserRolesState) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      stepper: {
        ...state.customRoles.stepper,
        productOptions: {
          ...state.customRoles.stepper.productOptions,
          isLoading: true,
        },
      },
    },
  }),

  [FetchProductsCustomRole.Success.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      customRoleProducts: payload.customRoleProducts,
      stepper: {
        ...state.customRoles.stepper,
        productOptions: {
          items: payload.formattedOptions,
          isLoading: false,
          error: null,
        },
      },
    },
  }),

  [FetchProductPermissions.Pending.TYPE]: (state: IUserRolesState) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      isLoading: true,
    },
  }),
  [FetchProductPermissions.Success.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      productPermissions: payload,
      isLoading: false,
      error: null,
    },
  }),
  [FetchProductPermissions.Error.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      productPermissions: [],
      isLoading: false,
      error: payload,
    },
  }),

  [FetchCustomRole.Pending.TYPE]: (state: IUserRolesState) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      isLoading: true,
    },
  }),
  [FetchCustomRole.Success.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      permissionTableSelectedScopes: payload.tableScopes,
      fetchedEditCustomRole: payload.customRole,
      isLoading: false,
      error: null,
      stepper: {
        ...state.customRoles.stepper,
        productField: {
          ...state.customRoles.stepper.productField,
          value: {
            ...state.customRoles.stepper.productField.value,
            label: payload.customRole.product,
            value: payload.customRole.productId,
            selected: true,
          },
          error: '',
        },
        roleNameField: {
          ...state.customRoles.stepper.roleNameField,
          value: payload.customRole.roleDisplayName,
          error: '',
        },
        roleDescriptionField: {
          ...state.customRoles.stepper.roleDescriptionField,
          value: payload.customRole.roleDescription,
          error: '',
        },
      },
    },
  }),
  [FetchCustomRole.Error.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      permissionTableSelectedScopes: {},
      fetchedEditCustomRole: null,
      isLoading: false,
      error: payload,
      stepper: {
        ...state.customRoles.stepper,
        productField: initialState.customRoles.stepper.productField,
        roleNameField: initialState.customRoles.stepper.roleNameField,
        roleDescriptionField:
          initialState.customRoles.stepper.roleDescriptionField,
      },
    },
  }),

  [CreateCustomRole.Pending.TYPE]: (state: IUserRolesState) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      isLoading: true,
    },
  }),
  [CreateCustomRole.Success.TYPE]: (state: IUserRolesState) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      isLoading: false,
      error: null,
    },
  }),
  [CreateCustomRole.Error.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      isLoading: false,
      error: payload,
    },
  }),

  [UpdateCustomRole.Pending.TYPE]: (state: IUserRolesState) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      isLoading: true,
    },
  }),
  [UpdateCustomRole.Success.TYPE]: (state: IUserRolesState) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      isLoading: false,
      error: null,
    },
  }),
  [UpdateCustomRole.Error.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      isLoading: false,
      error: payload,
    },
  }),

  [SetPermissionTableSearch.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      permissionTableSearch: payload,
    },
  }),

  [SetPermissionTableSelectedRows.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      permissionTableSelectedRows: payload,
    },
  }),

  [SetPermissionTableSelectedScopes.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      permissionTableSelectedScopes: payload,
    },
  }),

  [ChangeProductField.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      stepper: {
        ...state.customRoles.stepper,
        productField: payload,
      },
    },
  }),
  [ChangeTextField.TYPE]: (state: IUserRolesState, { payload }: AnyAction) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      stepper: {
        ...state.customRoles.stepper,
        ...payload,
      },
    },
  }),
  [ClearCustomRoleStepper.TYPE]: (state: IUserRolesState) => ({
    ...state,
    customRoles: initialState.customRoles,
  }),
  [SetActiveEditCustomRoleId.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      activeEditCustomRoleId: payload,
    },
  }),
  [DiscardCustomRoleStepper.TYPE]: (state: IUserRolesState) => ({
    ...state,
    customRoles: initialState.customRoles,
    editingUserRole: initialState.editingUserRole,
  }),
  [SetCustomRoleStepperStep.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      stepper: {
        ...state.customRoles.stepper,
        stepperStep: payload,
      },
    },
  }),
  [ToggleSummaryStepperBanner.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      toggleSummaryStepperBanner: payload,
    },
  }),
  [SetCustomRoleDirty.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      isCustomRoleDirty: payload,
    },
  }),
  [setSelectedAppPermissions.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      stepper: {
        ...state.customRoles.stepper,
        productCapabilities: {
          ...state.customRoles.stepper.productCapabilities,
          items: payload,
        },
      },
    },
  }),
  [SetProductsOptions.TYPE]: (state: IUser, { payload }: AnyAction) => ({
    ...state,
    bundledRoles: {
      ...state.bundledRoles,
      productsOptions: payload,
    },
  }),
  [SetRolesOptions.TYPE]: (state: IUser, { payload }: AnyAction) => ({
    ...state,
    bundledRoles: {
      ...state.bundledRoles,
      rolesOptions: payload,
    },
  }),
  [SetBundledRoles.TYPE]: (state: IUser, { payload }: AnyAction) => ({
    ...state,
    bundledRoles: {
      ...state.bundledRoles,
      bundledRolesData: payload,
    },
  }),
  [FetchApplicationScopes.Success.TYPE]: (
    state: IUserRolesState,
    { payload }: AnyAction
  ) => ({
    ...state,
    customRoles: {
      ...state.customRoles,
      applicationScopes: payload,
    },
  }),
});
