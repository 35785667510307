export enum UserStepperStates {
  import = 'IMPORT',
  invite = 'INVITE',
  groups = 'GROUPS',
  roles = 'ROLES',
}
export enum ErrorMessagesType {
  REQUIRED = 'required',
  EMAIL_FORMAT = 'emailFormat',
  EMAIL_EXISTS = 'emailExists',
  GROUP_NAME_EXISTS = 'groupNameExists',
  GROUP_NAME_EXISTS_KPA = 'groupNameExistsForKPA',
  GROUP_NAME_MAX_CHARACTERS = 'groupNameMaxCharacters',
  INVITE_FLOW_USER_NAME_MAX_CHARACTERS = 'inviteUserNameMaxCharacters',
}

export enum ErrorUploadMessageType {
  NOT_A_CSV_FILE = 'csvFile',
  TOO_LARGE_FILE = 'largeFile',
  FAILED_UPLOAD = 'failedUpload',
  MAX_USERS = 'limitOfUsers',
  CSV_HAS_NO_KEYS = 'csvNoKeys',
  ONE_FILE_LIMIT = 'oneFile',
}
