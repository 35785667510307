import { isArray, reduce } from 'lodash';
import { UserRoleType } from 'src/app/enums/UserRoles';
import { UserStatus } from 'src/app/enums/UserStatus';
import { IProductInstance, IUser } from 'src/app/interfaces/ICommon';
import { IRbacAssignedRole } from 'src/app/interfaces/IGroups';
import {
  IRoleToGroupRelationResp,
  IRbacUserRole,
} from 'src/app/interfaces/IUserRoles';
import {
  IDependentOption,
  IGroupToRoleRelation,
  IRolesRows,
  IRolesWithInstancesRows,
} from './interfaces';

export interface IResponseItem {
  response: IGroupToRoleRelation[];
  success?: boolean;
}
export type TGetSuccessResponses = (
  items: IResponseItem[]
) => IGroupToRoleRelation[];

export const getSuccessResponses: TGetSuccessResponses = (items) => {
  return reduce(
    items,
    (acc: IGroupToRoleRelation[], item: IResponseItem) => {
      if (item.success && item.response) {
        acc.push(...item.response);
      }
      return acc;
    },
    []
  );
};

export const sliceTableData = <T>(
  pageIndex: number,
  pageSize: number,
  rows: T[]
): T[] => {
  const from = pageIndex * pageSize;
  const to = (pageIndex + 1) * pageSize;
  return rows.slice(from, to);
};

export const isPendingUserPredicate: (user: IUser) => boolean = (user) => {
  return (
    user.status === UserStatus.STAGED || user.status === UserStatus.PROVISIONED
  );
};

export const isCurrentUserPredicate: (user: IUser) => boolean = (user) => {
  return (
    user.status === UserStatus.ACTIVE ||
    user.status === UserStatus.RECOVERY ||
    user.status === UserStatus.PASSWORD_EXPIRED
  );
};

export const checkProductInstancesSelection = (
  rowOptions: IRolesRows[] | IRolesWithInstancesRows[]
): any => {
  return rowOptions.filter((row) => {
    const roleInstances =
      'instancesOptions' in row &&
      'selectedRole' in row &&
      row.instancesOptions[row.selectedRole ?? ''];

    if (isArray(roleInstances)) {
      const unselectedInstances = roleInstances.filter(
        (instance: IDependentOption) => {
          return !instance.selected;
        }
      );

      return unselectedInstances.length === roleInstances.length;
    }
    return false;
  });
};

export function getKPAManagedTenantIdsHelper(
  assignedRoles: Array<IRbacAssignedRole | IRoleToGroupRelationResp>,
  allRoles: IRbacUserRole[],
  productInstances: IProductInstance[]
): string[] {
  const tenantIds = new Set<string>();

  assignedRoles.forEach((assignedRole) => {
    const matchingRole = allRoles.find(
      (role) => role.id === assignedRole.roleId
    );

    if (matchingRole) {
      if (matchingRole.type !== UserRoleType.BUNDLED) {
        tenantIds.add(assignedRole.tenantId);
      } else {
        const delegatableProductRoles = matchingRole.productRoles?.filter(
          (productRole) => productRole.delegatable
        );

        delegatableProductRoles?.forEach((productRole) => {
          const matchingProductInstances = productInstances.filter(
            (productInstance) =>
              productInstance.productType === productRole.productKey
          );

          matchingProductInstances.forEach((productInstance) => {
            tenantIds.add(productInstance.id);
          });
        });
      }
    }
  });

  return [...tenantIds];
}
