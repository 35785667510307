import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as resources from '../lang';

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react
    },
  })
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.error(`Failed to initialize i18n: ${err.message as string}`);
  });

export default i18n;
