import { orderBy } from 'lodash';
import { IEnterpriseObject } from '../../interfaces/IEnterpriseList';

export const filterEnterprises = (
  enterprises: IEnterpriseObject[]
): IEnterpriseObject[] => {
  const enterpriseArray = enterprises.filter((item: IEnterpriseObject) => {
    return item.name.length > 0 && item.id.length > 0;
  });

  return enterpriseArray;
};

export const sortEnterprises = (
  enterprises: IEnterpriseObject[]
): IEnterpriseObject[] => {
  const sortedEnterprises = orderBy(enterprises, ['name'], ['asc']);
  return sortedEnterprises;
};
