import { createSelector } from 'reselect';

import { IEnterprises } from './interfaces';
import { IState } from '../../core/redux/interfaces';

const getState = (state: IState): IEnterprises => state.enterprises || {};

const getEnterpriseDetails = createSelector(
  [getState],
  ({ enterpriseDetails }) => enterpriseDetails
);

const getAllEnterprises = createSelector(
  [getState],
  ({ allEnterprises }) => allEnterprises
);

const getEnterpriseIdAndName = createSelector(
  [getEnterpriseDetails],
  (enterpriseDetails) => {
    const { id, name } = enterpriseDetails;
    if (id !== null && name !== null) {
      return { id, name };
    } else {
      return { id: '', name: '' };
    }
  }
);

const getEnterpriseId = createSelector(
  [getEnterpriseIdAndName],
  ({ id }) => id
);

const getEnterpriseName = createSelector(
  [getEnterpriseIdAndName],
  ({ name }) => name
);

const getIsEnterprisesLoading = createSelector(
  [getState],
  ({ isLoading }) => isLoading
);

const getIsEnterpriseCreated = createSelector(
  [getState],
  ({ enterpriseCreated }) => enterpriseCreated
);

const getEnterprisesError = createSelector([getState], ({ error }) => error);

const getIsSuccessBannerVisible = createSelector(
  [getState],
  ({ isSuccessCreateEnterpriseBannerVisible }) =>
    isSuccessCreateEnterpriseBannerVisible
);

const getCreateEnterpriseError = createSelector(
  [getState],
  ({ createEnterpriseError }) => createEnterpriseError
);

const getIsCreateEnterpriseLoading = createSelector(
  [getState],
  ({ isCreateEnterpriseLoading }) => isCreateEnterpriseLoading
);

const getIsSelectEnterpriseModalOpened = createSelector(
  [getState],
  ({ isModalOpened }) => isModalOpened
);

const getPreSelectedEnterprise = createSelector(
  [getState],
  ({ preSelectedEnterprise }) => preSelectedEnterprise
);

const getIsDefaultEnterpriseLoaded = createSelector(
  [getState],
  ({ isDefaultEnterpriseLoaded }) => isDefaultEnterpriseLoaded
);

const getDefaultEnterpriseUserId = createSelector(
  [getState],
  ({ defaultEnterpriseUserId }) => defaultEnterpriseUserId
);

export {
  getIsEnterprisesLoading,
  getEnterprisesError,
  getAllEnterprises,
  getEnterpriseId,
  getEnterpriseName,
  getIsEnterpriseCreated,
  getIsSuccessBannerVisible,
  getCreateEnterpriseError,
  getIsCreateEnterpriseLoading,
  getIsSelectEnterpriseModalOpened,
  getPreSelectedEnterprise,
  getIsDefaultEnterpriseLoaded,
  getDefaultEnterpriseUserId,
  getEnterpriseDetails,
};
