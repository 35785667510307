import { createSelector } from 'reselect';

import { ILoader } from './interfaces';
import { IState } from '../../core/redux/interfaces';

export const getState = (state: IState): ILoader => state.loader || {};

export const getIsLoading = createSelector(
  getState,
  ({ pending }) => pending.length > 0
);

export const getIsCurrentActionLoading = createSelector(
  [getState, (state, currentLoader) => currentLoader],
  ({ pending }, currentLoader) => pending?.includes(currentLoader)
);
