export const HOME: string = '/';
export const OVERVIEW: string = 'overview';
export const USERS: string = 'users';
export const IDP_MANAGEMENT: string = 'idps';
export const DOMAIN_MANAGEMENT: string = 'domains';
export const DOMAIN_ADD_IDP: string = 'domains?directedBy=idp';
export const EDIT_USER: string = 'edit-user';
export const USER_ROLES: string = 'user-roles';
export const EDIT_USER_ROLE: string = 'edit-user-role';
export const CUSTOM_ROLE_STEPPER: string = 'custom-role-stepper';
export const BUNDLED_ROLE_STEPPER: string = 'bundled-role-stepper';
export const EDIT_GROUP: string = 'edit-group';
export const INVITE_USERS: string = 'invite-users';
export const GROUPS: string = 'groups';
export const ADD_GROUP: string = 'add-group';
export const CLAIM_CODE_MANAGEMENT: string = 'claim-code-management';
export const MAP_IDP_GROUPS: string = 'map-idp-groups';
export const OKTA_LOGIN_CALLBACK: string = '/login/callback';
export const OKTA_LOGOUT_CALLBACK: string = '/logout/callback';
export const ENTERPRISES: string = 'enterprises';
export const EDIT_IDP_GROUP: string = 'edit-idp-group';
export const ACTIVITY_LOG: string = 'activity-log';
export const SUPPORT: string = 'support';
