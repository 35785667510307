export const validateName = (value: string | undefined): boolean => {
  // Regex to match any of the special characters that are not allowed
  const specialChars = /[!@#$%^&*()_+=[\]{};:"\\|,<>/?~]/;

  // Regex for several checks:
  // - (^|\s)[\s']: Matches a space or the start of the string followed by a space or an apostrophe
  // - \s$: Matches a space at the end of the string
  // - '(?![a-zA-Z]): Matches an apostrophe not followed by an alphabetic character
  // - [^a-zA-Z]'$: Matches an apostrophe at the end not preceded by an alphabetic character
  const invalidWhitespaceOrApostrophe =
    /(^|\s)[\s']|\s$|'(?![a-zA-Z])|[^a-zA-Z]'$/;

  return !(
    value?.match(specialChars) != null ||
    value?.trim().length === 0 ||
    !value ||
    value?.match(invalidWhitespaceOrApostrophe) != null
  );
};

export const validateEmail = (mail: string | undefined): boolean => {
  const mailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (mail !== undefined && mailRegex.test(mail)) {
    return true;
  }
  return false;
};

/* Simplified following regex:
/^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$/;
Unit test cases : https://regex101.com/r/d5Yd6j/1
*/

export const validateDomain = (domain: string): boolean => {
  // Ensure the length of the domain does not exceed 253 characters and ends with an optional dot
  if (!/^.{1,253}\.?$/u.test(domain)) {
    return false;
  }

  // Split the input by dots to check each domain segment
  const segments = domain.split('.');

  // Ensure there are at least two segments
  if (segments.length < 2) {
    return false; // At least two segments are required
  }

  // Validate each domain segment
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];

    if (!validateDomainSegmentRegex(segment)) {
      return false;
    }

    // Ensure each segment consists of alphanumeric characters, hyphens, or underscores,
    // starts and ends with an alphanumeric character,
    // and has a length between 1 and 63 characters
    if (
      i !== 0 &&
      !/^[A-Za-z0-9][A-Za-z0-9-_]*[A-Za-z0-9]$/.test(segment) &&
      !/^xn--[a-z0-9]{1,63}$/.test(segment)
    ) {
      return false;
    }

    // Ensure that the last segment is not empty (e.g., to catch trailing dots)
    if (i === segments.length - 1 && segment.length === 0) {
      return false;
    }
  }

  // Ensure that the last segment (TLD) has a length between 2 and 63 characters
  if (
    segments[segments.length - 1].length < 2 ||
    segments[segments.length - 1].length > 63
  ) {
    return false;
  }

  return true; // If all checks pass, the domain is valid
};

export const validateDomainSegmentRegex = (segment: string): boolean => {
  // Ensure each segment does not start or end with a hyphen
  if (/^(-.*|.*-)$/.test(segment)) {
    return false;
  }
  // Ensure each segment does not contain consecutive hyphens, underscores, or periods and regex pattern for IDNs
  if (/--|__|\.\./.test(segment)) {
    return false;
  }
  // Ensure that each segment has a length between 1 and 63 characters
  if (segment.length < 1 || segment.length > 63) {
    return false;
  }
  return true;
};

export const allowedUserToAccessCustomerEnterprise = (
  accessTokenClaim: string,
  envVar: string
): boolean => !!accessTokenClaim && !!envVar && accessTokenClaim === envVar;
