export enum UserRoleType {
  CUSTOM = 'CUSTOM',
  STATIC = 'STATIC',
  BUNDLED = 'BUNDLED',
}

export enum UserRoleNotificationType {
  DELETE_USER_ROLE_SUCCESS = 'DELETE_USER_ROLE_SUCCESS',
  DELETE_USER_ROLE_ERROR = 'DELETE_USER_ROLE_ERROR',
  CREATE_USER_ROLE_SUCCESS = 'CREATE_USER_ROLE_SUCCESS',
  CREATE_USER_ROLE_ERROR = 'CREATE_USER_ROLE_ERROR',
  EDIT_USER_ROLE_SUCCESS = 'EDIT_USER_ROLE_SUCCESS',
  EDIT_USER_ROLE_ERROR = 'EDIT_USER_ROLE_ERROR',
}
