import { createSelector } from 'reselect';

import { IState } from 'src/app/core/redux/interfaces';
import { IIdp } from 'src/app/interfaces/IIdentityProvider';

export const getState = (state: IState): IIdp => state.idp || {};

export const getIdp = createSelector([getState], ({ idp }) => idp?.data ?? []);

export const getIdpData = createSelector([getIdp], (idps) =>
  idps.map((item) => ({
    name: item.inputs.idpName,
    link: item.outputs.acsUrl,
  }))
);
