import React, { Suspense, lazy } from 'react';
import { useBootstrapConfig } from '@cisco-sbg/cspe-mfe-shared-library';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import CommonLoader from './components/common/CommonLoader/CommonLoader';
import configureStore from './core/redux/store';

import './App.scss';
import './i18n';

const { store, persistor } = configureStore();

const LoadableBody = lazy(
  async () => await import(/* webpackChunkName: "apps" */ './Body')
);

const App: React.FC<{}> = () => {
  const { basepath, isStandalone } = useBootstrapConfig();

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={<CommonLoader />}>
          {isStandalone ? (
            <Router basename={basepath}>
              <LoadableBody />
            </Router>
          ) : (
            <LoadableBody />
          )}
        </Suspense>
      </PersistGate>
    </Provider>
  );
};

export default App;
