import { IInviteProduct } from '../../../interfaces/IOverview';

export const FETCH_INVITE_PRODUCTS_PENDING =
  'inviteProducts/FETCH_INVITE_PRODUCTS_PENDING';
export const FETCH_INVITE_PRODUCTS_SUCCESS =
  'inviteProducts/FETCH_INVITE_PRODUCTS_SUCCESS';
export const FETCH_INVITE_PRODUCTS_ERROR =
  'inviteProducts/FETCH_INVITE_PRODUCTS_ERROR';

export const FETCH_PRODUCTS_LIST_PENDING =
  'customers/FETCH_PRODUCTS_LIST_PENDING';
export const FETCH_PRODUCTS_LIST_SUCCESS =
  'customers/FETCH_PRODUCTS_LIST_SUCCESS';
export const FETCH_PRODUCTS_LIST_ERROR = 'customers/FETCH_PRODUCTS_LIST_ERROR';

export const ATTACH_PRODUCT_INVITE_PENDING =
  'inviteProducts/ATTACH_PRODUCT_INVITE_PENDING';
export const ATTACH_PRODUCT_INVITE_SUCCESS =
  'inviteProducts/ATTACH_PRODUCT_INVITE_SUCCESS';
export const ATTACH_PRODUCT_INVITE_ERROR =
  'inviteProducts/ATTACH_PRODUCT_INVITE_ERROR';

export const DECLINE_PRODUCT_INVITE_PENDING =
  'inviteProducts/DECLINE_PRODUCT_INVITE_PENDING';
export const DECLINE_PRODUCT_INVITE_SUCCESS =
  'inviteProducts/DECLINE_PRODUCT_INVITE_SUCCESS';
export const DECLINE_PRODUCT_INVITE_ERROR =
  'inviteProducts/DECLINE_PRODUCT_INVITE_ERROR';

export const SHOW_ATTACH_PRODUCT_MODAL =
  'inviteProducts/SHOW_ATTACH_PRODUCT_MODAL';
export const HIDE_ATTACH_PRODUCT_MODAL =
  'inviteProducts/HIDE_ATTACH_PRODUCT_MODAL';

export const SHOW_DECLINE_PRODUCT_MODAL =
  'inviteProducts/SHOW_DECLINE_PRODUCT_MODAL';
export const HIDE_DECLINE_PRODUCT_MODAL =
  'inviteProducts/HIDE_DECLINE_PRODUCT_MODAL';

export const SHOW_SUCCESS_ATTACH_PRODUCT_NOTIFICATION =
  'inviteProducts/SHOW_SUCCESS_ATTACH_PRODUCT_NOTIFICATION';
export const SHOW_SUCCESS_DECLINE_PRODUCT_NOTIFICATION =
  'inviteProducts/SHOW_SUCCESS_DECLINE_PRODUCT_NOTIFICATION';
export const HIDE_SUCESS_PRODUCT_NOTIFICATION =
  'inviteProducts/HIDE_SUCESS_PRODUCT_NOTIFICATION';

export const SHOW_ERROR_ATTACH_PRODUCT_NOTIFICATION =
  'inviteProducts/SHOW_ERROR_ATTACH_PRODUCT_NOTIFICATION';
export const SHOW_ERROR_DECLINE_PRODUCT_NOTIFICATION =
  'inviteProducts/SHOW_ERROR_DECLINE_PRODUCT_NOTIFICATION';
export const HIDE_ERROR_PRODUCT_NOTIFICATION =
  'inviteProducts/HIDE_ERROR_PRODUCT_NOTIFICATION';

export const invitationItemWithEmptyValues: IInviteProduct = {
  invitationId: '',
  productName: '',
  region: '',
  productExternalTenantId: '',
  email: '',
  status: '',
};

export const POST_BOOTSTRAP_PLATFORM_SUBSCRIPTION =
  'customers/POST_BOOTSTRAP_PLATFORM_SUBSCRIPTION';
