import { EventService } from '@cisco-sbg/cspe-mfe-shared-library';

interface INotifyFormPopulated {
  isDirty: boolean;
}

const formPopulatedService = EventService('FORM-POPULATED');

export const notifyFormPopulated = ({
  isDirty,
}: INotifyFormPopulated): void => {
  formPopulatedService.notifyWithValue({ isDirty });
};
