import {
  IActivityLogFilters,
  IActivityLogReceivedData,
} from 'src/app/interfaces/IActivityLog';
import {
  getActionFactory,
  getAsyncActionFactory,
} from '../../core/redux/utils/action-class-factory';
import { IOption } from '../groups/interfaces';

const createAction = getActionFactory('ACTIVITY_LOG');
const createAsyncAction = getAsyncActionFactory('ACTIVITY_LOG');

/* ----------------- async actions ----------------- */
export const FetchFilteredActivityLog = createAsyncAction<
  { toast?: Function },
  IActivityLogReceivedData
>('FETCH_FILTERED_ACTIVITY_LOG');

/* ----------------- sync actions ------------------ */
export const SetAppliedFilters = createAction<IActivityLogFilters>(
  'SET_ACTIVITY_LOG_APPLIED_FILTERS'
);

export const ShowActivityLogFilterDrawer = createAction<boolean>(
  'SHOW_ACTIVITY_LOG_FILTER_DRAWER'
);

export const HideActivityLogFilterDrawer = createAction<boolean>(
  'HIDE_ACTIVITY_LOG_FILTER_DRAWER'
);

/* ----------------- event filter drawer ----------------- */
export const SetSelectedUsers = createAction<IOption[]>('SET_SELECTED_USERS');

export const SetInitialDateTimeRange = createAction<{
  initialFetchedRange: [Date | null, Date | null];
}>('SET_INITIAL_DATE_TIME_RANGE_FILTER_DRAWER');

export const SetUserSelectedDateRange = createAction<{
  userSelectedDateRange: [Date | null, Date | null];
}>('USER_SELECTED_DATE_TIME_RANGE_FILTER_DRAWER');

export const SetActivityLogRequestDate = createAction<string>(
  'SET_LOGS_REQUEST_DATE'
);
