import React, { FC } from 'react';
import { CDSFlex } from '@ciscodesignsystems/cds-react-flex';
import {
  CDSStatusIcon,
  CDSStatusIconProps,
} from '@ciscodesignsystems/cds-react-icons';
import { CDSText } from '@ciscodesignsystems/cds-react-text';

export enum MappingStatuses {
  Added = 'added',
  Changed = 'changed',
  LeftChanged = 'leftChanged',
  RightChanged = 'rightChanged',
  Deleted = 'deleted',
  Unchanged = 'unchanged',
}

export const statusIconConfig: Record<
  MappingStatuses,
  { color: string; iconStatus: CDSStatusIconProps['status']; text: string }
> = {
  [MappingStatuses.Added]: {
    color: '#D9F4FF',
    iconStatus: 'positive',
    text: 'Added',
  },
  [MappingStatuses.Changed]: {
    color: '#FAEFB9',
    iconStatus: 'warning',
    text: 'Changed',
  },
  [MappingStatuses.LeftChanged]: {
    color: '#FAEFB9',
    iconStatus: 'warning',
    text: 'Changed',
  },
  [MappingStatuses.RightChanged]: {
    color: '#FAEFB9',
    iconStatus: 'warning',
    text: 'Changed',
  },
  [MappingStatuses.Deleted]: {
    color: '#FFE8E9',
    iconStatus: 'negative',
    text: 'Deleted',
  },
  [MappingStatuses.Unchanged]: {
    color: '#E1E4E8',
    iconStatus: 'inactive',
    text: 'Unchanged',
  },
};

interface IProps {
  status: MappingStatuses;
}

const MappingStatus: FC<IProps> = ({ status }) => {
  const { iconStatus, color, text } = statusIconConfig[status];

  return (
    <CDSFlex
      className={'mappingStatus'}
      align={'center'}
      gap={'xs'}
      justify={'center'}
      style={{ backgroundColor: color }}>
      <CDSStatusIcon status={iconStatus} />
      <CDSText
        size={'xxxs'}
        weight={'semi-bold'}>
        {text}
      </CDSText>
    </CDSFlex>
  );
};

export default MappingStatus;
