import { put, select, call, takeLatest } from 'redux-saga/effects';

import {
  fetchInviteProducts as fetchInviteProductsAction,
  fetchInviteProductsSuccess,
  fetchInviteProductsError,
  fetchProductsList as fetchProductsListAction,
  fetchProductsListSuccess,
  fetchProductsListError,
  attachInviteProductError,
  attachInviteProductSuccess,
  showSuccessAttachProductNotification,
  showSuccessDeclineProductNotification,
  showErrorDeclineProductNotification,
  showErrorAttachProductNotification,
  declineInviteProductSuccess,
  declineInviteProductError,
} from './actions';
import {
  FETCH_INVITE_PRODUCTS_PENDING,
  ATTACH_PRODUCT_INVITE_PENDING,
  DECLINE_PRODUCT_INVITE_PENDING,
  FETCH_PRODUCTS_LIST_PENDING,
  POST_BOOTSTRAP_PLATFORM_SUBSCRIPTION,
} from './constants';
import { phanesAPIClient } from '../../../api/clients';
import {
  AttachInviteProductType,
  IActionWithEnterpriseId,
  InviteProductsResponseType,
} from '../../../interfaces/IOverview';
import {
  IProductListErrorResponse,
  IProductListSuccessResponse,
  IProductTransformedData,
} from '../../../interfaces/IProductList';
import { fetchProductList as fetchProductListRequest } from '../../../services/fetchProductList';
import { getEnterpriseId } from '../../enterprises/selectors';

type EnterpriseProductsResponseType = { data: IProductTransformedData } | any;

export function* fetchInviteProducts(): Generator {
  try {
    const response: InviteProductsResponseType = yield phanesAPIClient.get(
      '/v1/productInstanceInvitation'
    );
    yield put(fetchInviteProductsSuccess(response.data));
  } catch (error) {
    yield put(fetchInviteProductsError(error as Error));
  }
}

export function* attachInviteProduct(
  action: AttachInviteProductType
): Generator {
  try {
    const { invitationId, enterpriseId } = action.payload;

    yield phanesAPIClient.post(
      `/v1/productInstanceInvitation/${invitationId}/accept`,
      {
        enterpriseId,
      }
    );

    yield put(fetchInviteProductsAction());
    yield put(fetchProductsListAction());

    yield put(attachInviteProductSuccess());
    yield put(showSuccessAttachProductNotification());
  } catch (error) {
    yield put(attachInviteProductError(error as Error));
    yield put(showErrorAttachProductNotification());
  }
}

export function* declineInviteProduct(
  action: AttachInviteProductType
): Generator {
  try {
    const { invitationId } = action.payload;

    yield phanesAPIClient.post(
      `/v1/productInstanceInvitation/${invitationId}/reject`,
      null
    );

    yield put(fetchInviteProductsAction());
    yield put(fetchProductsListAction());

    yield put(declineInviteProductSuccess());
    yield put(showSuccessDeclineProductNotification());
  } catch (error) {
    yield put(declineInviteProductError(error as Error));
    yield put(showErrorDeclineProductNotification());
  }
}

export function* postBootstrapPlatform(
  action: IActionWithEnterpriseId
): Generator {
  try {
    const { enterpriseId, enterpriseName } = action;

    yield phanesAPIClient.post(
      `/v1/enterprise/${enterpriseId}/platform-subscription`,
      {
        enterprise_name: enterpriseName,
      }
    );
  } catch (error) {}
}

// -- TODO: Refactor, move to separate saga file
export function* fetchProductsList(): Generator {
  const enterpriseId: string = (yield select(getEnterpriseId)) as string;

  const fetchProductList = async (): Promise<
    IProductListSuccessResponse | IProductListErrorResponse
  > => await fetchProductListRequest(enterpriseId);

  try {
    const response: EnterpriseProductsResponseType =
      yield call(fetchProductList);

    yield put(fetchProductsListSuccess(response.data));
  } catch (error) {
    yield put(fetchProductsListError(error as Error));
  }
}

export function* overviewSaga(): Generator {
  yield takeLatest(FETCH_INVITE_PRODUCTS_PENDING, fetchInviteProducts);
  yield takeLatest(ATTACH_PRODUCT_INVITE_PENDING, attachInviteProduct);
  yield takeLatest(DECLINE_PRODUCT_INVITE_PENDING, declineInviteProduct);
  yield takeLatest(POST_BOOTSTRAP_PLATFORM_SUBSCRIPTION, postBootstrapPlatform);

  yield takeLatest(FETCH_PRODUCTS_LIST_PENDING, fetchProductsList);
}
