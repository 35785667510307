import { IUserCSVColumns } from '../../interfaces/IUserCSVColumns';

export const FILE_LOADED = 'users/FILE_LOADED';
export const FILE_ERROR = 'users/FILE_ERROR';

export const SHOWS_INVITE_NOTIFICATION_AFTER_MS = 200;

export const MAX_INVITE_TABLE_ROWS = 20;

// The upload file size 4.8 MB
export const UPLOAD_CSV_FILE_MAX_SIZE: number = 4.8 * 1048576;

export const CSV_COLUMNS_KEYS: Array<keyof IUserCSVColumns> = [
  'firstName',
  'lastName',
  'email',
];
export const CSV_COLUMNS_TEXT: Array<keyof IUserCSVColumns> = [
  'First Name',
  'Last Name',
  'Email',
];
