export const SET_ENTERPRISE_DETAILS = 'enterprises/SET_ENTERPRISE_DETAILS';

export const SET_ENTERPRISE_CREATED = 'enterprises/SET_ENTERPRISE_CREATED';

export const FETCH_ENTERPRISE_DETAILS_PENDING =
  'enterprises/FETCH_ENTERPRISE_DETAILS_PENDING';

export const FETCH_ENTERPRISE_DETAILS_SUCCESS =
  'enterprises/FETCH_ENTERPRISE_DETAILS_SUCCESS';

export const FETCH_ENTERPRISE_DETAILS_ERROR =
  'enterprises/FETCH_ENTERPRISE_DETAILS_ERROR';

export const RESET_ENTERPRISE_DETAILS = 'enterprises/RESET_ENTERPRISE_DETAILS';

export const FETCH_ENTERPRISES_PENDING =
  'enterprises/FETCH_ENTERPRISES_PENDING';

export const FETCH_ENTERPRISES_SUCCESS =
  'enterprises/FETCH_ENTERPRISES_SUCCESS';

export const FETCH_ENTERPRISES_ERROR = 'enterprises/FETCH_ENTERPRISES_ERROR';

export const CREATE_ENTERPRISE_PENDING =
  'enterprises/CREATE_ENTERPRISE_PENDING';

export const CREATE_ENTERPRISE_SUCCESS =
  'enterprises/CREATE_ENTERPRISE_SUCCESS';

export const CREATE_ENTERPRISE_ERROR = 'enterprises/CREATE_ENTERPRISE_ERROR';

export const SHOW_SUCCESS_CREATE_ENTERPRISE_BANNER =
  'enterprises/SHOW_SUCCESS_CREATE_ENTERPRISE_BANNER';

export const HIDE_SUCCESS_CREATE_ENTERPRISE_BANNER =
  'enterprises/HIDE_SUCCESS_CREATE_ENTERPRISE_BANNER';

export const OPEN_ENTERPRISE_MODAL = 'enterprises/OPEN_ENTERPRISE_MODAL';

export const CLOSE_ENTERPRISE_MODAL = 'enterprises/CLOSE_ENTERPRISE_MODAL';

export const PRE_SELECT_ENTERPRISE = 'enterprises/PRE_SELECT_ENTERPRISE';

export const SET_DEFAULT_ENTERPRISE_PENDING =
  'enterprises/SET_DEFAULT_ENTERPRISE_PENDING';

export const SET_DEFAULT_ENTERPRISE_SUCCESS =
  'enterprises/SET_DEFAULT_ENTERPRISE_SUCCESS';

export const SET_DEFAULT_ENTERPRISE_ERROR =
  'enterprises/SET_DEFAULT_ENTERPRISE_ERROR';

export const SET_DEFAULT_ENTERPRISE_USER_ID =
  'enterprises/SET_DEFAULT_ENTERPRISE_USER_ID';

export const HIDE_SUCCESS_SWITCH_ENTERPRISE_TOAST =
  'enterprises/HIDE_SUCCESS_SWITCH_ENTERPRISE_TOAST';
