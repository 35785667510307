import {
  getActionFactory,
  getAsyncActionFactory,
} from '../../core/redux/utils/action-class-factory';
import { IProductInstance, IUser } from '../../interfaces/ICommon';

const createAction = getActionFactory('COMMON');
const createAsyncAction = getAsyncActionFactory('COMMON');

/* ----------------- async actions ----------------- */
export const FetchAllProductsInstances = createAsyncAction<
  null,
  IProductInstance[]
>('FETCH_ALL_PRODUCTS_INSTANCES');

export const FetchAllUsers = createAsyncAction<null, IUser[]>(
  'FETCH_ALL_USERS'
);

export const FetchFeatureFlags = createAsyncAction<null, string[]>(
  'FETCH_FEATURE_FLAGS'
);

/* ----------------- sync actions ------------------ */
export const ClickAway = createAction<{
  path?: string;
  shouldRedirect?: boolean;
  signOutCallback?: Function;
}>('CLICK_AWAY');

export const SetBootstrapConfig = createAction<{
  isStandalone: boolean;
  basePath: string | null;
}>('SET_BOOTSTRAP_CONFIG');

export const SetNavigateConfig = createAction<{
  redirectPath: string | null;
  enterpriseId: string | null;
  navigateFn: Function;
}>('SET_NAVIGATE_CONFIG');
