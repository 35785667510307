import { all } from 'redux-saga/effects';
import { activityLogSaga } from 'src/app/components/activity-log/saga';
import { commonSaga } from '../../components/common/saga';
import { enterprisesSaga } from '../../components/enterprises/saga';
import { groupsSaga } from '../../components/groups/saga';
import { idpSaga } from '../../components/idp-management/saga';
import { overviewSaga } from '../../components/overview/store/saga';
import { userRolesSaga } from '../../components/user-roles/saga';
import { usersSaga } from '../../components/users/saga';

export default function* rootSaga(): Generator {
  yield all([
    activityLogSaga(),
    enterprisesSaga(),
    overviewSaga(),
    usersSaga(),
    userRolesSaga(),
    groupsSaga(),
    commonSaga(),
    idpSaga(),
  ]);
}
