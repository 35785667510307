import { AnyAction } from 'redux';
import { createReducer } from 'redux-create-reducer';

import { IActivityLogState } from 'src/app/interfaces/IActivityLog';
import {
  FetchFilteredActivityLog,
  HideActivityLogFilterDrawer,
  SetActivityLogRequestDate,
  SetAppliedFilters,
  SetSelectedUsers,
  SetInitialDateTimeRange,
  SetUserSelectedDateRange,
  ShowActivityLogFilterDrawer,
} from './actions';

export const initialState: IActivityLogState = {
  appliedFilters: {
    pageIndex: 0,
    pageSize: 30,
  },
  activityLogData: {
    data: {
      currentPage: 0,
      totalPages: 0,
      totalRecords: 0,
      logs: [],
    },
    error: null,
    isLoading: false,
  },
  activityLogRequestDate: '',
  isActivityLogFilterDrawerVisible: false,
  filteredEventsDrawer: {
    userSelectedDateRange: null,
    initialFetchedRange: null,
    hasUserTouchedFilters: false,
    eventSubTypes: [],
    selectedUsers: [],
  },
};

export default createReducer(initialState, {
  [FetchFilteredActivityLog.Pending.TYPE]: (state: IActivityLogState) => ({
    ...state,
    activityLogData: {
      ...initialState.activityLogData,
      isLoading: true,
    },
  }),

  [FetchFilteredActivityLog.Success.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    activityLogData: {
      ...initialState.activityLogData,
      data: payload,
      isLoading: false,
    },
  }),

  [FetchFilteredActivityLog.Error.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    activityLogData: {
      ...initialState.activityLogData,
      error: payload,
      isLoading: false,
    },
  }),

  [ShowActivityLogFilterDrawer.TYPE]: (state: IActivityLogState) => ({
    ...state,
    isActivityLogFilterDrawerVisible: true,
  }),

  [HideActivityLogFilterDrawer.TYPE]: (state: IActivityLogState) => ({
    ...state,
    isActivityLogFilterDrawerVisible: false,
  }),

  [SetAppliedFilters.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    appliedFilters: {
      ...state.appliedFilters,
      ...payload,
    },
  }),

  [SetSelectedUsers.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    filteredEventsDrawer: {
      ...state.filteredEventsDrawer,
      selectedUsers: payload,
    },
  }),
  [SetInitialDateTimeRange.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    filteredEventsDrawer: {
      ...state.filteredEventsDrawer,
      initialFetchedRange: payload.initialFetchedRange,
      hasUserTouchedFilters: false,
    },
  }),

  [SetUserSelectedDateRange.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    filteredEventsDrawer: {
      ...state.filteredEventsDrawer,
      userSelectedDateRange: payload.userSelectedDateRange,
      hasUserTouchedFilters: true,
    },
  }),

  [SetActivityLogRequestDate.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    activityLogRequestDate: payload,
  }),
});
